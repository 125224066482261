/** @jsxImportSource @emotion/react */
import {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "reset-css";
import { string } from "superstruct";
import FormZone from "../../../../components/FormZone";
import {
  GpsField,
  GpsFieldLocation,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../../utilities/Fields";
import { FieldsFormContext } from "../FieldsForm";
import CoordinatesSelector, { Place } from "./CoordinatesSelector";
import DestinationSelector from "./DestinationSelector";
import LocationSelector from "./LocationSelector";
import MapSelector from "./MapSelector";
import OptionSelector from "./OptionSelector";

export type GpsFieldHandlerProps = {
  field: GpsField;
};

export default function GpsFieldHandler(props: GpsFieldHandlerProps) {
  const { field } = props;

  const form = useContext(FieldsFormContext);
  const { value, setValue } = form.useFieldManager(field.id, string());
  useFieldRequiredContraint(field);
  useMountedField(field);

  // Option
  const [optionValue, setOptionValue] = useState<string | null>(
    value ? value.split("_")[0] : null
  );
  const option = useMemo(() => {
    if (optionValue === null) return null;
    const find = field.options.find((o) => o.label === optionValue);
    if (find) return find;
    return optionValue;
  }, [optionValue, field.options]);

  // Location
  const [location, setLocation] = useState<GpsFieldLocation | null>(() => {
    if (option === null) return null;
    if (typeof option === "string") return null;
    if (!value) return null;
    const matchingLoc = option.locations.find((l) => {
      const matchingDestination = l.options.find((d) => d.value === value);
      if (!!matchingDestination) return true;
    });
    return matchingLoc || null;
  });

  useEffect(() => {
    if (option === null) return;
    if (typeof option === "string") return;
    if (!location) return;
    if (!option.locations.includes(location)) setLocation(null);
  }, [option, location]);

  useEffect(() => {
    if (option === null) return;
    if (typeof option === "string") return;
    if (location) return;
    if (!value) return;
    const matchingLoc = option.locations.find((l) => {
      const matchingDestination = l.options.find((d) => d.value === value);
      if (!!matchingDestination) return true;
    });
    if (matchingLoc) setLocation(matchingLoc);
  }, [option, location, value]);

  // Place

  const [place, setPlace] = useState<Place | null>(null);

  let secondField: ReactNode = null;
  if (option === null) secondField = null;
  else if (typeof option === "string")
    secondField = <CoordinatesSelector place={place} onSelect={setPlace} />;
  else
    secondField = (
      <LocationSelector
        option={option}
        selection={location}
        onSelect={setLocation}
      />
    );

  let content: ReactNode = null;
  if (option === null) secondField = null;
  else if (typeof option === "string")
    content = (
      <MapSelector
        field={field}
        place={place}
        value={value}
        onChange={setValue}
        onPlaceChange={setPlace}
        pec_mode={option}
      />
    );
  else
    content = (
      <DestinationSelector
        field={field}
        option={option}
        location={location}
        value={value}
        onSelect={setValue}
      />
    );

  return (
    <Fragment>
      <FormZone>
        <OptionSelector
          field={field}
          selection={optionValue}
          onSelect={setOptionValue}
        />
        {secondField}
      </FormZone>
      {content}
    </Fragment>
  );
}
