/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { get } from "lodash";
import { rgba } from "polished";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Selector from "../../../../components/Selector";
import Text from "../../../../components/Text";
import getDepartementNumber from "../../../../utilities/getDepartementNumber";
import Layout from "../../../../utilities/Layout";
import Theme from "../../../../utilities/Theme";
import useFieldCss from "../../../../utilities/useFieldCss";
import { getAddressComponent } from "./MapSelector";

export type Coordinates = { lat: number; lng: number };

type CoordinatesSelectorProps = {
  place: Place | null;
  onSelect: (value: Place | null) => any;
};

type AutocompleteResult = {
  description: string;
  place_id: string;
};

export type Place = {
  description: string;
  departement: string;
  city: string;
  lat: number;
  lng: number;
};

export default function CoordinatesSelector(props: CoordinatesSelectorProps) {
  const { place, onSelect } = props;

  const [typed, setTyped] = useState<string | null>(null);
  const debounced = useDebounce(typed, 500);
  const [results, setResults] = useState<Array<AutocompleteResult> | null>(
    null
  );

  useEffect(() => {
    if (typed) return;
    setResults(null);
  }, [typed]);

  useEffect(() => {
    if (!debounced) return;
    const google = get(window, "google");
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: debounced }, (results: any) => {
      setResults(results);
    });
  }, [debounced]);

  const selectPlace = useCallback((result: AutocompleteResult) => {
    setResults(null);
    const google = get(window, "google");
    const map = new google.maps.Map(document.createElement("div"));
    const service = new google.maps.places.PlacesService(map);
    service.getDetails(
      { placeId: result.place_id, fields: ["geometry", "address_components"] },
      (details: any) => {
        const components = details.address_components;
        const cpComponent = components.find((c: any) =>
          c.types.includes("postal_code")
        );
        const city =
          getAddressComponent(details, "locality") ||
          getAddressComponent(details, "administrative_area_level_3") ||
          getAddressComponent(details, "administrative_area_level_2") ||
          getAddressComponent(details, "administrative_area_level_1");
        if (!city || !cpComponent) return;
        const dep = getDepartementNumber(cpComponent.long_name);
        onSelect({
          description: result.description,
          departement: dep,
          city,
          lat: details.geometry.location.lat(),
          lng: details.geometry.location.lng(),
        });
      }
    );
  }, []);

  const theme = Theme.useTheme();
  const fieldsCss = useFieldCss();

  const selectorCss = css({
    position: "relative",
  });

  const dropdownCss = css(fieldsCss.inputContainer, {
    position: "absolute",
    top: "calc(100% + 2px)",
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    zIndex: 10000,
  });

  const dropdownItemCss = css({
    padding: Layout.S / 2,
    "&:hover": {
      background: rgba(theme.validationBackground, 0.2),
    },
  });

  let resultsNode: ReactNode = null;
  if (results) {
    resultsNode = (
      <div css={dropdownCss}>
        {results.map((r) => (
          <div
            key={r.place_id}
            onClick={() => selectPlace(r)}
            css={dropdownItemCss}
          >
            <Text>{r.description}</Text>
          </div>
        ))}
      </div>
    );
  }

  const display = place ? place.description : typed;

  const onFocus = useCallback(() => {
    if (!place) return;
    setTyped(place.description);
    onSelect(null);
  }, [place]);

  return (
    <div css={selectorCss}>
      <Selector.Input
        value={display}
        onChange={setTyped}
        onFocus={onFocus}
        type="text"
        placeholder="Entrez une adresse"
      />
      {resultsNode}
    </div>
  );
}

// Hook
// T is a generic type for value parameter, our case this will be string
function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
