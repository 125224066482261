/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { ReactNode, useContext } from "react";
import HighlightContext from "../utilities/HighlightContext";
import Layout from "../utilities/Layout";
import Theme from "../utilities/Theme";
import Grid from "./Grid";

type FormZoneProps = {
  children: Array<ReactNode>;
  columns?: 1 | 2;
};

export default function FormZone(props: FormZoneProps) {
  const { children, columns } = props;

  const hightlighted = useContext(HighlightContext);
  const theme = Theme.useTheme();

  const containerCss = css`
    background-color: ${hightlighted
      ? rgba(theme.panelBackground, 0.1)
      : theme.panelBackground};
    margin: 10px 0px;
  `;

  return (
    <div css={containerCss}>
      <Grid columns={columns} gap={Layout.S} outerGap>
        {children.map((child, i) => {
          return <div key={i}>{child}</div>;
        })}
      </Grid>
    </div>
  );
}
