/** @jsxImportSource @emotion/react */
import { Fragment, useMemo } from "react";
type PriceProps = {
  price: number;
  before?: string;
  after?: string;
};

export default function Price(props: PriceProps) {
  const { price, before, after } = props;

  const formattedPrice = useMemo(() => Price.format(price), [price]);

  return (
    <Fragment>
      {before || ""}
      {formattedPrice}
      {after || ""}
    </Fragment>
  );
}

Price.format = (price: number) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(price);
};
