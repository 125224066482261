/** @jsxImportSource @emotion/react */
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "reset-css";
import { string } from "superstruct";
import Panel3 from "../../../components/Panel3";
import Selector from "../../../components/Selector";
import Spacer from "../../../components/Spacer";
import Text from "../../../components/Text";
import {
  ArboRadioField,
  ArboRadioOption,
  ArboRadioOptionWithValue,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import StepContext from "../../../utilities/StepContext";
import { FieldsFormContext } from "./FieldsForm";

export type ArboRadioFieldHandlerProps = {
  field: ArboRadioField;
};

type Selector = { key: string; index: number; options: Array<ArboRadioOption> };
type OptionsWithValues = {
  [value: string]: { indexes: Array<number>; option: ArboRadioOptionWithValue };
};

export default function ArboRadioFieldHandler(
  props: ArboRadioFieldHandlerProps
) {
  const { field } = props;
  const form = useContext(FieldsFormContext);
  const { value, setValue } = form.useFieldManager(field.id, string());
  useFieldRequiredContraint(field);
  useMountedField(field);

  const optionsWithValues = useMemo(() => {
    function flattenOptions(
      options: Array<ArboRadioOption>,
      results: OptionsWithValues = {},
      parent: Array<number> = []
    ): OptionsWithValues {
      options.forEach((o, i) => {
        if ("value" in o) {
          results[o.value] = { indexes: [...parent, i], option: o };
        } else {
          flattenOptions(o.options, results, [...parent, i]);
        }
      });
      return results;
    }
    const optionsWithValues = flattenOptions(field.options);
    return optionsWithValues;
  }, [field.options]);

  const [selectedIndexes, setSelectedIndexes] = useState<Array<number>>([]);
  const [resume, setResume] = useState<string | null>(null);

  useEffect(() => {
    if (!value) {
      return;
    } else if (!optionsWithValues[value]) {
      setValue(null);
      setResume(null);
      setSelectedIndexes([]);
    } else {
      const optionWithValue = optionsWithValues[value];
      setResume(optionWithValue.option.label);
      setSelectedIndexes(optionWithValue.indexes);
    }
  }, [value, optionsWithValues]);

  const selectors = useMemo<Array<Array<ArboRadioOption>>>(() => {
    const output: Array<Array<ArboRadioOption>> = [];
    for (let i = 0; i < selectedIndexes.length + 1; i++) {
      if (i === 0) output.push(field.options);
      else {
        const previousSelector = output[i - 1];
        const previousSelectorSelectedIndex = selectedIndexes[i - 1];
        const previousSelectorOption =
          previousSelector[previousSelectorSelectedIndex];
        if ("options" in previousSelectorOption) {
          output.push(previousSelectorOption.options);
        } else {
          break;
        }
      }
    }
    return output;
  }, [selectedIndexes, field]);

  const onSelect = useCallback(
    (index: number, option: ArboRadioOption | null) => {
      setSelectedIndexes((current) => {
        if (option) {
          const out = [...current];
          out[index] = selectors[index].indexOf(option);
          return out;
        } else {
          return current;
        }
      });
      if (option) {
        if ("value" in option) {
          setValue(option.value);
          setResume(option.label);
        } else {
          setValue(null);
          setResume(null);
        }
      } else {
        setValue(null);
        setResume(null);
        setSelectedIndexes((current) => [...current].slice(0, index));
      }
    },
    [selectors]
  );

  StepContext.useResumeUpdater(field, resume);

  return (
    <Fragment>
      {selectors.map((selector, i) => (
        <ArboRadioSelector
          index={i}
          options={selector}
          key={selectedIndexes.slice(0, i).join("/") || "root"}
          description={field.description}
          selection={selector[selectedIndexes[i]]}
          onSelectOption={onSelect}
        />
      ))}
    </Fragment>
  );
}

type ArboRadioSelectorProps = {
  index: number;
  description: string;
  options: Array<ArboRadioOption>;
  selection: ArboRadioOption | null;
  onSelectOption: (index: number, selection: ArboRadioOption | null) => any;
};

function ArboRadioSelector(props: ArboRadioSelectorProps) {
  const { description, options, index, onSelectOption, selection } = props;

  const keyExtractor = useCallback((option: ArboRadioOption) => {
    return option.label;
  }, []);

  const renderOption = useCallback(
    (option: ArboRadioOption, selected: boolean) => {
      return (
        <Panel3
          highlighted={selected}
          content={
            <Fragment>
              <Spacer.Half />
              <Text typo="subheading" html={option.label} />
              <Spacer.Half />
              <Text>{option.description}</Text>
              <Spacer.Half />
            </Fragment>
          }
        />
      );
    },
    [index]
  );

  const onSelect = useCallback((s: ArboRadioOption | null) => {
    onSelectOption(index, s);
  }, []);

  return (
    <Fragment>
      <Text center>{description}</Text>
      <Selector.Options
        multiple={false}
        selection={selection}
        options={options}
        keyExtractor={keyExtractor}
        renderOption={renderOption}
        columns={3}
        gap={20}
        margin={true}
        onSelect={onSelect}
      />
    </Fragment>
  );
}
