/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

type IconProps = {
  name: string;
  size?: number;
  rotate?: number;
  color?: string
};

export default function Icon(props: IconProps) {
  const { name, size, rotate = 0, color } = props;

  let iconCss = css`
    font-size: ${size + "px" || "unset"};
    transform: rotate(${rotate}deg);
    transition: transform 200ms;
    display: inline-block;
    width: 1em;
    height: 1em;
  `;
  if (color) {
    iconCss = css(iconCss, { color })
  }
  return <span css={iconCss} className={`icon icon-${name}`} />;
}
