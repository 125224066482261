/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import useResizeObserver from "@react-hook/resize-observer";
import { random } from "lodash";
import { PropsWithChildren, useMemo, useRef, useState } from "react";
import getAsset from "../utilities/getAsset";
import Layout from "../utilities/Layout";
import Theme from "../utilities/Theme";
import useResponsive from "../utilities/useResponsive";

type WavyContentProps = PropsWithChildren<{
  transitionKey?: string;
}>;

export default function WavyContent(props: WavyContentProps) {
  const ContentComponent = useResponsive(
    {
      800: MobileWavyContent,
      bigger: DesktopWavyContent,
    },
    []
  );

  return <ContentComponent {...props} />;
}

function MobileWavyContent(props: WavyContentProps) {
  const contentCss = css`
    background-color: white;
  `;
  return <div css={contentCss}>{props.children}</div>;
}

function DesktopWavyContent(props: WavyContentProps) {
  const { transitionKey } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);

  const theme = Theme.useTheme();

  useResizeObserver(contentRef, (entry) => {
    setContentHeight(entry.contentRect.height);
  });

  useResizeObserver(containerRef, (entry) => {
    setContainerHeight(entry.contentRect.height);
  });

  const wavesHeight = useMemo(() => {
    if (contentHeight === null) return 100;
    if (containerHeight === null) return 100;
    let height = containerHeight - contentHeight;
    if (height < 100) height = 100;
    return height;
  }, [contentHeight, containerHeight]);

  const outerCss = css`
    border-radius: 20px;
    height: 90vh;
    overflow: hidden;
    background: white;
    height: calc(100vh - ${Layout.desktopHeaderHeight}px - ${Layout.S * 2}px);
    display: flex;
    flex-direction: column;
    position: relative;
  `;

  const fillerCss = css`
    background: ${theme.wavesBackground};
    flex-grow: 1;
    flex-shrink: 0;
  `;

  const wavesContainer = css`
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: ${wavesHeight}px;
    transition: height 1000ms;
    display: flex;
    flex-direction: column;
  `;

  const contentCss = css`
    position: relative;
    flex-grow: 1;
    overflow: auto;
  `;

  const disapearingCss = css`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.5;
  `;

  return (
    <div css={outerCss} ref={containerRef}>
      <div css={wavesContainer}>
        <Waves />
        <div css={fillerCss} />
      </div>
      <div css={contentCss}>
        <div ref={contentRef}>{props.children}</div>
      </div>
    </div>
  );
}

function Waves() {
  const containerCss = css`
    height: 50px;
    position: relative;
    flex-shrink: 0;
  `;

  return (
    <div css={containerCss}>
      <Wave
        height={50}
        width={900}
        side="right"
        opacity={50}
        duration={8000}
        coef={0.1}
      />
      <Wave
        height={40}
        width={1200}
        side="left"
        opacity={70}
        duration={9000}
        coef={0.1}
      />
      <Wave
        height={25}
        width={1500}
        side="left"
        opacity={100}
        duration={10000}
        coef={0.2}
      />
    </div>
  );
}

type WaveProps = {
  width: number;
  opacity: number;
  height: number;
  side: "left" | "right";
  duration: number;
  coef: number;
};

function Wave(props: WaveProps) {
  const theme = Theme.useTheme();
  const { width, height, duration, opacity, side, coef } = props;
  const animName = useMemo(() => `wave${random(1000, 9999)}`, []);
  const anim = [0.5 + coef, coef, 0.5 - coef, 1 - coef].join(",");

  const containerCss = css`
    height: ${height}px;
    background: ${theme.wavesBackground};
    mask: url(${getAsset("wave.svg")});
    mask-size: ${width}px ${height}px;
    mask-position: 100px 0px;
    opacity: ${opacity}%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;

    animation: ${animName} ${duration}ms linear infinite;

    @keyframes ${animName} {
      0% {
        mask-position: 0px 0px;
      }
      100% {
        mask-position: ${side === "left" ? "" : "-"}${width}px 0px;
      }
    }
  `;

  return <div css={containerCss}></div>;
}
