import { without } from "lodash";
import { nanoid } from "nanoid";
import React, { useContext } from "react";
import { AnyField } from "./Fields";
import StepContext from "./StepContext";
import Updater from "./Updater";

const updater = new Updater();

export type WorkflowItem = { key: string; label: string };

export type QuotationStep = {
  key: string;
  title: string;
  description: string;
  fields: Array<AnyField>;
  no_field_placeholder: string;
  workflow_mapped: Array<WorkflowItem>;
};

export type QuotationLastStep = {
  results: true;
};

export type QuotationFormValues = {
  [stepKey: string]: QuotationFormStepValues;
};

export type QuotationFormStepValues = {
  [fieldId: string]: any;
};

export type QuotationResume = Array<{
  stepKey: string;
  stepLabel: string;
  points: Array<{ info: string; value: string | Array<string> }>;
}>;

export type QuotationResponses = {
  [key: string]: any;
};

export type QuotationModel = {
  id: string;
  workflow: Array<WorkflowItem>;
  steps: Array<QuotationStep>;
  formValues: QuotationFormValues;
  resume: QuotationResume;
  result: QuotationResult | null | false;
};

const localStorageKey = "quotations2";
const stored = localStorage.getItem(localStorageKey);
let quotations: { [key: string]: QuotationModel } = {};

if (stored) {
  const parsed = Object.values(JSON.parse(stored)) as Array<QuotationModel>;
  parsed.forEach((q) => {
    if (!q.resume) return;
    if (!q.resume.length) return;
    quotations[q.id] = q;
  });
}

function create(initialStep: QuotationStep) {
  const id = nanoid(8);
  quotations[id] = {
    id,
    workflow: initialStep.workflow_mapped,
    formValues: {},
    steps: [initialStep],
    result: null,
    resume: [],
  };
  write();
  return id;
}

function saveResult(id: string, result: QuotationResult | false | null) {
  const quotation = quotations[id];
  if (!quotation) throw new Error("No quotation found");
  quotation.result = result;
  quotations[id] = { ...quotation };
  write();
  return result;
}

function addResume(
  id: string,
  step: QuotationStep,
  field: AnyField,
  value: string | Array<string> | null
) {
  const quotation = quotations[id];
  if (!quotation) throw new Error("No quotation found");

  const workflowItem = quotation.workflow.find((i) => i.key === step.key);
  if (!workflowItem) return;

  const info = "title" in field ? field.title : null;
  if (info === null) return;

  let category = quotation.resume.find((i) => i.stepKey === step.key);
  if (!category) {
    category = { stepKey: step.key, stepLabel: workflowItem.label, points: [] };
    quotation.resume.push(category);
  }

  let point = category.points.find((p) => p.info === info);
  if (!point) {
    if (!value) {
      if (category.points.length === 0)
        quotation.resume = without(quotation.resume, category);
    } else {
      point = { info, value };
      category.points.push(point);
    }
  } else {
    if (!value) {
      category.points = without(category.points, point);
      if (category.points.length === 0)
        quotation.resume = without(quotation.resume, category);
    } else {
      point.value = value;
    }
  }

  quotations[id] = { ...quotation };
  write();
}

function saveFormValues(
  id: string,
  step: QuotationStep,
  stepFormValues: QuotationFormStepValues
) {
  const quotation = quotations[id];
  if (!quotation) throw new Error("No quotation found");
  const stepKey = StepContext.getStepKey(step);
  quotation.formValues[stepKey] = { ...stepFormValues };
  quotations[id] = { ...quotation };
  write();
}

function addStep(id: string, currentStep: QuotationStep, step: QuotationStep) {
  const quotation = quotations[id];
  if (!quotation) throw new Error("No quotation found");
  const workflow = step.workflow_mapped;
  const newSteps: Array<QuotationStep> = [];
  workflow.forEach((item) => {
    if (item.key === step.key) newSteps.push(step);
    else {
      const existing = quotation.steps.find((s) => s.key === item.key);
      if (existing) newSteps.push(existing);
    }
  }, []);
  quotation.workflow = step.workflow_mapped;
  quotation.steps = newSteps;
  quotations[id] = { ...quotation };
  write();
  return newSteps;
}

function write() {
  localStorage.setItem(localStorageKey, JSON.stringify(quotations));
  updater.update();
}

function useQuotation(id: string) {
  return updater.useValue(() => {
    if (id in quotations) return quotations[id];
    else return null;
  }, [id]);
}

function useQuotations() {
  return updater.useValue(() => Object.values(quotations));
}

const context = React.createContext<QuotationModel | null>(null);

function useContextQuotation() {
  const quotation = useContext(context);
  if (!quotation) throw new Error("No quotation incontext");
  return quotation;
}

function removeQuotation(id: string) {
  const all = Object.values(quotations);
  const without = all.filter((q) => q.id !== id);
  quotations = {};
  without.forEach((q) => (quotations[q.id] = q));
  write();
}

function getResponses(id: string) {
  const quotation = quotations[id];
  if (!quotation) throw new Error("No quotation found");
  const formValues = quotation.formValues;
  const steps = quotation.steps;
  const responses: QuotationResponses = {};
  steps.forEach((step) => {
    const stepKey = StepContext.getStepKey(step);
    const stepName = StepContext.getStepName(step);
    if (!formValues[stepKey]) return;
    if (step.fields.length === 1) {
      responses[stepName] = formValues[stepKey][step.fields[0].id];
    } else {
      responses[stepName] = formValues[stepKey];
    }
  });
  return responses;
}

function getFlatResponses(id: string, untilStep: string | null) {
  const quotation = quotations[id];
  if (!quotation) throw new Error("No quotation found");
  const steps = quotation.steps;
  let responses: QuotationResponses = {};
  for (let step of steps) {
    const stepKey = StepContext.getStepKey(step);
    responses = { ...responses, ...quotation.formValues[stepKey] };
    if (step.key === untilStep) break;
  }
  return responses;
}

function setCurrentStep(quotationId: string, stepKey: string) {}

const Quotations = {
  create,
  addStep,
  useQuotation,
  saveResult,
  addResume,
  Provider: context.Provider,
  useContextQuotation,
  useQuotations,
  removeQuotation,
  saveFormValues,
  getResponses,
  getFlatResponses,
};

export default Quotations;

export type QuotationResult = {
  calculated_datas: {
    A: number;
    ASSIGNE: number;
    CAF: number;
    EMITTER: string;
    FR: number;
    MODE_DE_TRANSPORT: string;
    OM: null | { om: number; omr: number };
    OM_VALUES: { OM: number; OMR: number };
    OPTIONS: [];
    P: number;
    PEC: string;
    PEC_LOCATION: string;
    PORT_ARRIVEE: string;
    PORT_DE_DEPART: string;
    PT: string;
    PT_LOCATION: string;
    QUOTE_ID: number;
    QUOTE_UUID: string;
    TAXES: number;
    TOTAL_A_PAYER: number;
    TOTAL_HT: number;
    TOTAL_REUNION: number;
    TSM: number;
    TSM_CONTENEUR_DETAILS: [];
    TVA: number;
    TVA_RU: number;
    TVA_RU_VALUE: number;
    TVA_VALUE: number;
    V: number;
  };
  delay: {
    formatted: string;
    raw: number;
  };
  pdf_file: string;
  url_reservation: string;
  summary: string;
  workflow: Array<WorkflowItem>;
};
