/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Layout from "../utilities/Layout";

type SpacerBaseProps = {
  grow?: boolean;
};

type SpacerFreeProps = SpacerBaseProps & {
  size: number;
};
function SpacerFree(props: SpacerFreeProps) {
  const containerCss = css`
    width: ${props.size}px;
    height: ${props.size}px;
    flex-shrink: 0;
    ${props.grow ? `flex-grow : 1` : ""}
  `;
  return <div css={containerCss} />;
}

const Spacer = (props: SpacerBaseProps) => (
  <SpacerFree size={Layout.S} {...props} />
);
Spacer.Half = (props: SpacerBaseProps) => (
  <SpacerFree size={Layout.S / 2} {...props} />
);
Spacer.Double = (props: SpacerBaseProps) => (
  <SpacerFree size={Layout.S * 2} {...props} />
);
Spacer.Grow = (props: SpacerBaseProps) => (
  <div
    css={css`
      flex-grow: 1;
    `}
  />
);

export default Spacer;
