/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useContext, useMemo } from "react";
import "reset-css";
import { number, string, union } from "superstruct";
import Selector from "../../../components/Selector";
import { SelectorHelpProps } from "../../../components/Selector/Help";
import {
  SelectField,
  SelectFieldOption,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import StepContext from "../../../utilities/StepContext";
import useFieldCss from "../../../utilities/useFieldCss";
import { FieldsFormContext } from "./FieldsForm";

export type SelectFieldHandlerProps = {
  field: SelectField;
} & SelectorHelpProps;

export default function SelectFieldHandler(props: SelectFieldHandlerProps) {
  const { field } = props;

  const form = useContext(FieldsFormContext);
  const { value, setValue } = form.useFieldManager(
    field.id,
    union([string(), number()])
  );

  useFieldRequiredContraint(field);
  useMountedField(field);

  const fieldCss = useFieldCss();

  const selectedOption = useMemo(() => {
    return field.options.find((o) => o.value === value) || null;
  }, [value, field.options]);

  const onSelect = useCallback((value: SelectFieldOption | null) => {
    setValue(value ? value.value : null);
  }, []);

  const keyExtractor = useCallback((option: SelectFieldOption) => {
    return option.value.toString();
  }, []);

  const renderOption = useCallback((option: SelectFieldOption) => {
    return option.label;
  }, []);

  StepContext.useResumeUpdater(
    field,
    selectedOption ? selectedOption.label : null
  );

  return (
    <Fragment>
      <label htmlFor={field.id} css={fieldCss.label}>
        {field.title}
      </label>
      <Selector.Select
        id={field.id}
        selection={selectedOption}
        placeholder={"Choisissez..."}
        help={field.description}
        options={field.options}
        keyExtractor={keyExtractor}
        onSelect={onSelect}
        renderOption={renderOption}
        disabled={field.disabled}
        {...props}
      />
    </Fragment>
  );
}
