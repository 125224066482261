/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Text from "../components/Text";
import Css from "../utilities/Css";
export default function Loading() {
  const containerCss = css(Css.centerContent, {
    flexGrow: 1,
    paddingBlock: 40,
  });

  return (
    <div css={containerCss}>
      <Text typo="heading">Chargement en cours...</Text>
    </div>
  );
}
