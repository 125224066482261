/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { PropsWithChildren } from "react";
import Layout from "../utilities/Layout";
import useResponsive from "../utilities/useResponsive";

type WidtherProps = PropsWithChildren<{}>;

export default function Widther(props: WidtherProps) {
  const containerCss = useResponsive(
    {
      800: css({
        width: "100%",
      }),
      bigger: css({
        margin: "auto",
        maxWidth: 1280,
      }),
    },
    []
  );

  const wrapperCss = useResponsive(
    {
      800: css({}),
      bigger: css({
        paddingLeft: Layout.P,
        paddingRight: Layout.P,
      }),
    },
    []
  );

  return (
    <div css={containerCss}>
      <div css={wrapperCss}>{props.children}</div>
    </div>
  );
}
