/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Css from "../utilities/Css";
import Layout from "../utilities/Layout";
import StepContext from "../utilities/StepContext";
import Theme from "../utilities/Theme";
import useResponsive from "../utilities/useResponsive";
import Icon from "./Icon";
import Spacer from "./Spacer";
import Text from "./Text";

type WorkflowContainerProps = {};

export default function WorkflowContainer(props: WorkflowContainerProps) {
  const quotation = StepContext.useQuotation();
  const workflow = quotation.workflow;
  const step = StepContext.useStep();

  const navigate = useNavigate();

  const p = useMemo<Omit<WorkflowProps, "onSelectPoint">>(
    () => ({
      points: workflow.map((s) => ({
        key: s.key,
        label: s.label,
        details: [],
        selectable: quotation.steps.map((s) => s.key).includes(s.key),
      })),
      maximumPoints: workflow.length,
      currentPoint: workflow.map((w) => w.key).indexOf(step.key),
    }),
    [workflow]
  );

  const onSelectPoint = useCallback(
    (point: Point) => {
      navigate(`/quotations/${quotation.id}/${point.key}`);
    },
    [quotation]
  );

  const Component = useResponsive({
    800: WorkflowMobile,
    bigger: WorkflowDesktop,
  });
  return <Component {...p} onSelectPoint={onSelectPoint} />;
}

type WorkflowProps = {
  points: Array<Point>;
  maximumPoints: number;
  currentPoint: number;
  onSelectPoint: (item: Point) => any;
};

function WorkflowMobile(props: WorkflowProps) {
  const { points, currentPoint, maximumPoints, onSelectPoint } = props;
  const theme = Theme.useTheme();
  const [folded, setFlolded] = useState<boolean>(true);

  const selectablePoints = useMemo(
    () => points.filter((p) => p.selectable),
    [points]
  );

  const mainElement = points[points.length - 1];
  const otherElements = useMemo(
    () => points.slice(0, points.length - 1),
    [points]
  );

  const flodableCss = css`
    display: flex;
    flex-direction: column-reverse;
  `;

  const pointsCss = css`
    padding: ${Layout.P}px;
    border-bottom: 6px solid ${rgba(theme.bordersColor, 0.3)};
  `;

  const renderPoint = useCallback(
    (point: Point, index: number) => {
      const pointCss = css`
        ${pointsCss}
        height : 80px;
        border-bottom-color: ${index === currentPoint
          ? theme.validationBackground
          : rgba(theme.bordersColor, 0.5)};
      `;

      return (
        <div css={pointCss} key={index} onClick={() => onSelectPoint(point)}>
          <Text singleLine typo="step" color={theme.primaryText}>
            {point.label}
          </Text>
        </div>
      );
    },
    [currentPoint]
  );

  const containerCss = css`
    display: block;
    position: sticky;
    height: ${folded ? "80" : selectablePoints.length * 80}px;
    background-color: white;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 3;
    overflow: hidden;
    transition: height 500ms;
  `;

  const foldCss = css`
    ${Css.buttonReset}
    ${Css.centerContent}
    position : absolute;
    top: 0px;
    right: 0px;
    width: 72px;
    height: 72px;
    transform: rotate(${!folded ? "0deg" : "180deg"});
    transition: transform 200ms;
  `;

  /*
    {renderPoint(mainElement, -1)}
      {folded ? null : (
        <div css={flodableCss}>{otherElements.map(renderPoint)}</div>
      )}
    */

  const itemsCss = css`
    transform: translateY(${folded ? currentPoint * -80 : 0}px);
    transition: transform 500ms;
  `;

  return (
    <div>
      <menu css={containerCss}>
        <div css={itemsCss}>{selectablePoints.map(renderPoint)}</div>
        <button css={foldCss} onClick={() => setFlolded(!folded)}>
          <Icon name="chevron-up" size={24} />
        </button>
      </menu>
    </div>
  );
}

function WorkflowDesktop(props: WorkflowProps) {
  const { points, maximumPoints, currentPoint, onSelectPoint } = props;
  const theme = Theme.useTheme();

  const containerCss = css`
    display: flex;
    flex-direction: row;
    padding-left: ${Layout.S / 2}px;
    padding-right: ${Layout.S / 2}px;
    border-bottom: 1px solid ${rgba(theme.bordersColor, 0.3)};
  `;

  const pointsCss = css`
    width: ${`${100 / maximumPoints}%`};
    padding-left: ${Layout.S / 2}px;
    padding-right: ${Layout.S / 2}px;
    padding-top: ${Layout.S}px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 6px solid transparent;
  `;

  const renderPoint = useCallback(
    (point: Point, index: number) => {
      let pointCss = css`
        ${pointsCss}
        border-right :1px solid ${rgba(theme.bordersColor, 0.15)};
        &:last-child {
          border-right: none;
        }
        border-bottom-color: ${index === currentPoint
          ? theme.validationBackground
          : "transparent"};
      `;

      if (point.selectable) {
        pointCss = css`
          ${pointCss}
          cursor : pointer;
          :hover {
            background-color: ${rgba(theme.validationBackground, 0.1)};
          }
        `;
      } else {
        pointCss = css`
          ${pointCss};
          opacity: 0;
        `;
      }

      const onSelect = point.selectable
        ? () => onSelectPoint(point)
        : undefined;

      return (
        <div css={pointCss} key={index} onClick={onSelect}>
          <div>
            {point.details.map((text, i) => {
              return (
                <Text singleLine typo="minor" color={theme.primaryText} key={i}>
                  {text}
                </Text>
              );
            })}
            <Spacer.Half />
          </div>
          <div>
            <Text singleLine typo="step" color={theme.primaryText}>
              {point.label}
            </Text>
            <Spacer.Half />
          </div>
        </div>
      );
    },
    [currentPoint]
  );

  return <menu css={containerCss}>{points.map(renderPoint)}</menu>;
}

type Point = {
  key: string;
  details: Array<string>;
  label: string;
  selectable: boolean;
};
