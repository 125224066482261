/** @jsxImportSource @emotion/react */
import {
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import "reset-css";
import { string } from "superstruct";
import Button from "../../../components/Button";
import Panel3 from "../../../components/Panel3";
import PriceTag from "../../../components/PriceTag";
import Field from "../../../components/Selector";
import Spacer from "../../../components/Spacer";
import Text from "../../../components/Text";
import Environment from "../../../utilities/Environment";
import {
  RadioField,
  RadioOption,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import StepContext from "../../../utilities/StepContext";
import { FieldsFormContext } from "./FieldsForm";

export type RadioFieldHandlerProps = {
  field: RadioField;
};

export default function RadioFieldHandler(props: RadioFieldHandlerProps) {
  const { field } = props;
  const form = useContext(FieldsFormContext);

  const { isSubmitting, hasErrors } = form.useState();
  const { value, setValue } = form.useFieldManager(field.id, string());
  useFieldRequiredContraint(field);
  useMountedField(field);

  const keyExtractor = useCallback((option: RadioOption) => {
    return option.value;
  }, []);

  const selectedOption = useMemo(() => {
    return field.options.find((o) => keyExtractor(o) === value) || null;
  }, [field.options, value]);

  const onSelect = useCallback((option: RadioOption | null) => {
    if (option) {
      setValue(option.value);
    } else {
      setValue(null);
    }
  }, []);

  useEffect(() => {
    if (!selectedOption) return;
    if (selectedOption.redirection) {
      const redirect = Environment.findString("REACT_APP_REDIRECT_TO");
      alert(
        "Afin de poursuivre, reprennez votre devis sur le site " + redirect
      );
      window.location.href = redirect;
    }
  }, [selectedOption]);

  StepContext.useResumeUpdater(
    field,
    selectedOption ? selectedOption.label : ""
  );

  const renderOption = (option: RadioOption, selected: boolean) => {
    const showSubmitButton = option.redirect === false ? false : true;

    let buttonNode: ReactNode = null;
    if (showSubmitButton) {
      if (selected) {
        buttonNode = (
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              centered
              submit
              label="Continuer"
              loading={isSubmitting}
              disabled={hasErrors}
              form={form.id}
            />
          </div>
        );
      } else {
        buttonNode = <Button.Invisible />;
      }
      buttonNode = (
        <Fragment>
          <Spacer.Half />
          {buttonNode}
        </Fragment>
      );
    }

    const price = option.price;

    const priceNode =
      price !== undefined ? (
        <Fragment>
          <Spacer.Half />
          <PriceTag before="Estimation : " price={price} />
        </Fragment>
      ) : null;

    return (
      <Panel3
        highlighted={selected}
        content={
          <Fragment>
            <Spacer.Half />
            <Text typo="subheading" html={option.label} />
            <Spacer.Half />
            <Text html={option.description} />
            <Spacer.Grow />
            {priceNode}
            {buttonNode}
            <Spacer.Half />
          </Fragment>
        }
      />
    );
  };

  return (
    <Fragment>
      <Field.Options
        multiple={false}
        selection={selectedOption}
        onSelect={onSelect}
        options={field.options}
        keyExtractor={keyExtractor}
        renderOption={renderOption}
        columns={3}
        gap={20}
        margin={true}
      />
    </Fragment>
  );
}
