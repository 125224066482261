import { useMemo } from "react";
import {
  AnyField,
  ArboRadioField,
  CheckboxDefaultField,
  CheckboxField,
  EmailField,
  FNAAField,
  FNAAGroupField,
  GpsField,
  NumberField,
  RadioField,
  SelectField,
  SwitchDetailsField,
  TextareaField,
  TextField,
} from "./Fields";

export type InputFormZone = {
  type: "input-form-zone";
  fields: Array<
    | TextField
    | NumberField
    | FNAAField
    | SelectField
    | EmailField
    | TextareaField
  >;
};

type FormZone =
  | RadioField
  | ArboRadioField
  | SwitchDetailsField
  | GpsField
  | CheckboxField
  | CheckboxDefaultField
  | InputFormZone
  | FNAAGroupField;

export type FormZones = Array<FormZone>;

export default function useFormZones(fields: Array<AnyField>) {
  return useMemo(() => {
    const form: FormZones = [];
    let inputFormZone: InputFormZone = { type: "input-form-zone", fields: [] };

    function flush() {
      if (inputFormZone.fields.length) {
        form.push(inputFormZone);
        inputFormZone = { type: "input-form-zone", fields: [] };
      }
    }

    for (let field of fields) {
      if (
        field.type === "radio" ||
        field.type === "arbo-radio" ||
        field.type === "switch_details" ||
        field.type === "gps" ||
        field.type === "checkbox" ||
        field.type === "checkbox_default" ||
        field.type === "fnaa-group"
      ) {
        flush();
        form.push(field);
      } else if (
        field.type === "text" ||
        field.type === "number" ||
        field.type === "fnaa" ||
        field.type === "select" ||
        field.type === "email" ||
        field.type === "textarea"
      ) {
        inputFormZone.fields.push(field);
      }
    }

    flush();
    return form;
  }, [fields]);
}
