/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import "reset-css";
import Spacer from "../../components/Spacer";
import Text from "../../components/Text";
import { FormZones } from "../../utilities/useFormZones";
import FieldHandler from "./FieldHandler";
import InputFormZoneHandler from "./InputFormZoneHandler";

type FormHandlerProps = {
  zones: FormZones;
  placeholder?: string;
};

export default function FormHandler(props: FormHandlerProps) {
  const { zones, placeholder } = props;

  if (zones.length === 0) {
    if (placeholder) {
      return (
        <Fragment>
          <Text center>{placeholder}</Text>
          <Spacer />
        </Fragment>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Fragment>
        {zones.map((zone, i) => {
          if (zone.type === "input-form-zone") {
            return <InputFormZoneHandler zone={zone} key={i} />;
          } else {
            const field = zone;
            return <FieldHandler field={field} key={field.id} />;
          }
        })}
      </Fragment>
    );
  }
}
