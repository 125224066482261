import { flatten } from "lodash";
import { useContext, useEffect } from "react";
import { any } from "superstruct";
import { FieldsFormContext } from "../screens/Step/FieldHandler/FieldsForm";
import { HtmlString } from "./basic_types";

export type AnyField =
  | RadioField
  | ArboRadioField
  | TextField
  | EmailField
  | TextareaField
  | NumberField
  | SwitchDetailsField
  | NumberField
  | FNAAField
  | FNAAGroupField
  | SelectField
  | CheckboxField
  | CheckboxDefaultField
  | GpsField;

type FieldBase = {
  id: string;
  required?: false;
};

// Radio Field

export type RadioField = FieldBase & {
  description: string;
  title: string;
  type: "radio";
  options: Array<RadioOption>;
};

export type RadioOption = {
  label: HtmlString;
  value: string;
  description: string;
  redirect: boolean;
  redirection: boolean;
  price?: number;
};

// ArboRadio Field

export type ArboRadioField = FieldBase & {
  description: string;
  title: string;
  type: "arbo-radio";
  options: Array<ArboRadioOption>;
};

export type ArboRadioOption =
  | ArboRadioOptionWithSubOptions
  | ArboRadioOptionWithValue;

export type ArboRadioOptionWithSubOptions = {
  label: HtmlString;
  description: string;
  options: Array<ArboRadioOption>;
};

export type ArboRadioOptionWithValue = {
  label: HtmlString;
  description: string;
  value: string;
};

// TextField

export type TextField = FieldBase & {
  description: string;
  title: string;
  type: "text";
  disabled?: boolean;
};

// EmailField

export type EmailField = FieldBase & {
  description: string;
  title: string;
  type: "email";
  value: string;
  disabled?: boolean;
};

// TextareaField

export type TextareaField = FieldBase & {
  description: string;
  title: string;
  type: "textarea";
  value: string;
  required: false;
  disabled?: boolean;
};

// FNAAField

export type FNAAField = FieldBase & {
  description: string;
  title: string;
  type: "fnaa";
  fields: Array<TextField | SelectField>;
};

// FNAAGroupField

export type FNAAGroupField = FieldBase & {
  description: string;
  title: string;
  type: "fnaa-group";
  "data-type": "fnaa";
  options: Array<FNAAGroupOptions>;
};

export type FNAAGroupOptions = {
  id: string;
  label: string;
  fields: Array<TextField | SelectField>;
};

// NumberField

export type NumberField = FieldBase & {
  title: string;
  description: string;
  type: "number";
  "data-type": "currency";
  constraints?: { min?: number; max?: number };
};

// SelectField

export type SelectField = FieldBase & {
  type: "select";
  title: string;
  description: string;
  options: Array<SelectFieldOption>;
  disabled?: boolean;
};

export type SelectFieldOption = { value: string | number; label: string };

// SwitchDetailsField

export type SwitchDetailsField = FieldBase & {
  type: "switch_details";
  options: Array<SwitchDetailsOption>;
};

export type SwitchDetailsOption = {
  label: string;
  description: string;
  fields: Array<AnyField>;
};

// GpsField

export type GpsField = FieldBase & {
  default_location: {
    country: string;
    description: string;
    label: string;
    lat: number;
    lng: number;
    zoom: number;
  };
  type: "gps";
  title: string;
  description: string;
  "use-domicile": boolean;
  "use-convoyage": boolean;
  "use-text-filter": boolean;
  options: Array<GpsFieldOption>;
};

export type GpsFieldOption = {
  label: string;
  description: string;
  locations: Array<GpsFieldLocation>;
  selected: boolean;
  placeholder?: string;
  placeholder_filter?: string;
};

export type GpsFieldLocation = {
  id: string;
  country: string;
  description: string;
  label: string;
  lat: number;
  lng: number;
  my_komela_id: string;
  options: Array<GpsFieldDestination>;
  transit: string;
  value: string;
};

export type GpsFieldDestination = {
  btn_label: string;
  description: string;
  id: number;
  label: string;
  title: string;
  total_price: number;
  transit: string;
  value: string;
  warning: Array<any>;
};

// CheckboxField

export type CheckboxField = FieldBase & {
  "data-type": "boolean";
  description: string;
  lightbox?: FieldImage;
  title: string;
  type: "checkbox";
};

// CheckboxDefaultField

export type CheckboxDefaultField = FieldBase & {
  "data-type": "boolean";
  description: string;
  lightbox?: FieldImage;
  title: string;
  type: "checkbox_default";
};

type FieldImage = {
  src: string;
  description: string;
};

function getSubFields(field: AnyField) {
  let base = [field];
  if (field.type === "switch_details") {
    field.options.forEach((o) => {
      const subfields = flatten(o.fields.map((f) => getSubFields(f)));
      base = [...base, ...subfields];
    });
  }
  return base;
}

export function useMountedField(field: AnyField) {
  const form = useContext(FieldsFormContext);
  const { value, setValue } = form.useFieldManager(field.id, any());
  useEffect(() => {
    setValue(value);
    return () => {
      form.clean(field.id);
    };
  }, [field.id]);
}

export function useFieldRequiredContraint(field: AnyField) {
  const form = useContext(FieldsFormContext);
  const { value, setError } = form.useFieldManager(field.id, any());
  const isRequired = field.required === false ? false : true;
  useEffect(() => {
    const isEmpty =
      value === null || value === undefined || value === "" || value === false;
    const isErrored = isRequired && isEmpty;
    setError("Champ requis", isErrored);
    return () => {
      setError("Champ requis", false);
    };
  }, [value, isRequired]);
}

const Fields = { getSubFields };

export default Fields;
