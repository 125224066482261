/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { get, set } from "lodash";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "reset-css";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Panel3 from "../components/Panel3";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import Layout from "../utilities/Layout";
import Theme from "../utilities/Theme";
import Environment from "./Environment";
import useBooleanState from "./useBooleanState";

function Reviews() {
  const theme = Theme.useTheme();
  const placeId = Environment.findString("REACT_APP_PLACE_ID");

  const [reviews, setReviews] = useState<Array<Review> | null>(
    get(window, "google_reviews") || null
  );

  const [rating, setRating] = useState<number | null>(
    get(window, "google_rating") || null
  );

  const loadReviews = useCallback(() => {
    const google = get(window, "google");
    const map = new google.maps.Map(document.createElement("div"));
    const service = new google.maps.places.PlacesService(map);
    service.getDetails({ placeId, fields: ["reviews", "rating"] }, (r: any) => {
      setRating(r.rating);
      setReviews(r.reviews);
      set(window, "google_reviews", r.reviews);
      set(window, "google_rating", r.rating);
    });
  }, []);

  useEffect(() => {
    loadReviews();
  }, [loadReviews]);

  const contentCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  });

  const imageCss = css({
    width: 50,
    height: 50,
    backgroundPosition: "center",
    backgroundSize: "contain",
  });

  const nextCss = css({
    position: "absolute",
    top: Layout.S * 3,
    right: Layout.S,
    zIndex: 10000,
    transform: `rotate(90deg)`,
    "&:hover": {
      color: theme.validationBackground,
      cursor: "pointer",
    },
  });
  const prevCss = css({
    position: "absolute",
    top: Layout.S * 3,
    left: Layout.S,
    zIndex: 10000,
    transform: `rotate(-90deg)`,
    "&:hover": {
      color: theme.validationBackground,
      cursor: "pointer",
    },
  });

  if (!reviews) return null;

  return (
    <div css={{ width: "100%" }}>
      <Text typo="heading" center>
        Comment s'est passé votre devis ?
      </Text>
      <Spacer />
      <Text typo="subheading" center color={Theme.useTheme().priceTag}>
        Note globale : {rating} sur 5
      </Text>
      <Spacer />
      <Button
        href={Environment.findString("REACT_APP_LINK_TO_ADD_REVIEW")}
        label={"Laisser un avis"}
        newTab
        centered
      />
      <div
        css={{
          position: "relative",
          paddingInline: Layout.S * 4,
          paddingBlock: Layout.S,
        }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={Layout.S}
          modules={[Autoplay, Navigation]}
          loop={true}
          autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
          navigation={{ nextEl: "#next", prevEl: "#prev" }}
        >
          {reviews.map((review, i) => {
            return (
              <SwiperSlide key={i}>
                <Panel3>
                  <div css={contentCss}>
                    <Spacer.Half />
                    <div
                      css={css(imageCss, {
                        backgroundImage: `url(${review.profile_photo_url})`,
                      })}
                    />
                    <Spacer.Half />
                    <div css>
                      <Icon
                        name="star"
                        color={
                          review.rating >= 1
                            ? theme.validationBackground
                            : theme.contentText
                        }
                        size={20}
                      />
                      <Icon
                        name="star"
                        color={
                          review.rating >= 2
                            ? theme.validationBackground
                            : theme.contentText
                        }
                        size={20}
                      />
                      <Icon
                        name="star"
                        color={
                          review.rating >= 3
                            ? theme.validationBackground
                            : theme.contentText
                        }
                        size={20}
                      />
                      <Icon
                        name="star"
                        color={
                          review.rating >= 4
                            ? theme.validationBackground
                            : theme.contentText
                        }
                        size={20}
                      />
                      <Icon
                        name="star"
                        color={
                          review.rating >= 5
                            ? theme.validationBackground
                            : theme.contentText
                        }
                        size={20}
                      />
                    </div>
                    <Spacer.Half />
                    <Text typo="label" center>
                      Par {review.author_name}
                    </Text>
                    <Text typo="body" center>
                      {review.relative_time_description}
                    </Text>
                    <Spacer.Half />
                    <ReadMore>
                      <Text center>{review.text}</Text>
                    </ReadMore>
                    <Spacer.Half />
                  </div>
                </Panel3>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div id="next" css={nextCss}>
          <Icon name="chevron-up" size={24} />
        </div>
        <div id="prev" css={prevCss}>
          <Icon name="chevron-up" size={24} />
        </div>
      </div>
      <Spacer />
    </div>
  );
}

type Review = {
  author_name: string;
  text: string;
  profile_photo_url: string;
  relative_time_description: string;
  rating: number;
};

export default Reviews;

function ReadMore(props: { children: ReactNode }) {
  const [unfoldable, setUnfoldable] = useBooleanState(false);
  const [unfolded, setUnfolded] = useBooleanState(false);
  const maxHeight = 50;

  const contentRef = useRef<HTMLDivElement>(null);

  const containerCss = css({
    maxHeight:
      unfolded && contentRef.current
        ? contentRef.current.getBoundingClientRect().height
        : maxHeight,
    transition: "max-height 300ms",
    overflow: "hidden",
    maskImage:
      unfoldable && !unfolded
        ? "linear-gradient(black 70%, transparent 100%)"
        : undefined,
    maskMode: "alpha",
  });

  useEffect(() => {
    if (!contentRef.current) return;
    const rect = contentRef.current.getBoundingClientRect();
    setUnfoldable.to(rect.height > maxHeight);
  }, [maxHeight]);

  const color = Theme.useTheme().validationBackground;

  return (
    <Fragment>
      <div css={containerCss}>
        <div ref={contentRef}>{props.children}</div>
      </div>
      {unfoldable ? (
        <Fragment>
          <Spacer />
          <div
            onClick={setUnfolded.toggle}
            css={css({ display: "flex", alignItems: "center" })}
          >
            <Spacer.Half />
            <Text color={color} typo="button">
              {unfolded ? "Replier" : "En savoir plus"}
            </Text>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
