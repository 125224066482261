/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useContext, useMemo } from "react";
import "reset-css";
import { any } from "superstruct";
import Button from "../../../components/Button";
import Panel3 from "../../../components/Panel3";
import Selector from "../../../components/Selector";
import Spacer from "../../../components/Spacer";
import Text from "../../../components/Text";
import {
  SwitchDetailsField,
  SwitchDetailsOption,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import Layout from "../../../utilities/Layout";
import useFormZones from "../../../utilities/useFormZones";
import FormHandler from "../FormHandler";
import { FieldsFormContext } from "./FieldsForm";

export type SwitchDetailsFieldHandlerProps = {
  field: SwitchDetailsField;
};

export default function SwitchDetailsFieldHandler(
  props: SwitchDetailsFieldHandlerProps
) {
  const { field } = props;

  const form = useContext(FieldsFormContext);
  const { value, setValue } = form.useFieldManager(field.id, any());

  useFieldRequiredContraint(field);
  useMountedField(field);

  const selection = useMemo(() => {
    return field.options.find((o) => o.label === value) || null;
  }, [field, value]);

  const keyExtractor = useCallback((option: SwitchDetailsOption) => {
    return option.label;
  }, []);

  const renderOption = useCallback(
    (option: SwitchDetailsOption, selected: boolean) => {
      return <SwitchDetailOption option={option} selected={selected} />;
    },
    []
  );

  const onSelectionChange = useCallback((s: SwitchDetailsOption | null) => {
    if (!s) return;
    setValue(s.label);
  }, []);

  return (
    <Selector.Options
      selection={selection}
      options={field.options}
      keyExtractor={keyExtractor}
      renderOption={renderOption}
      multiple={false}
      onSelect={onSelectionChange}
      gap={Layout.S}
      margin={true}
    />
  );
}

type SwitchDetailOptionProps = {
  option: SwitchDetailsOption;
  selected: boolean;
};

function SwitchDetailOption(props: SwitchDetailOptionProps) {
  const { option, selected } = props;

  const zones = useFormZones(option.fields);
  const form = useContext(FieldsFormContext);
  const { isSubmitting, hasErrors } = form.useState();

  return (
    <Panel3
      horizontal={false}
      content={
        <Fragment>
          <Spacer.Half />
          <Text typo="subheading" html={option.label} />
          <Spacer.Half />
          <Text>{option.description}</Text>
          <Spacer.Half />
        </Fragment>
      }
      after={
        selected ? (
          <Fragment>
            <FormHandler zones={zones} />
            <Spacer.Half />
            <Button
              centered
              submit
              label="Continuer"
              disabled={hasErrors}
              loading={isSubmitting}
              form={form.id}
            />
            <Spacer.Half />
          </Fragment>
        ) : null
      }
      highlighted={selected}
    />
  );
}
