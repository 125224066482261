/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { ReactNode } from "react";
import HighlightContext from "../utilities/HighlightContext";
import Layout from "../utilities/Layout";
import Theme from "../utilities/Theme";

type Panel3Props = {
  before?: ReactNode;
  after?: ReactNode;
  content?: ReactNode;
  children?: ReactNode;
  highlighted?: boolean;
  horizontal?: boolean;
  onClick?: () => any;
};

export default function Panel3(props: Panel3Props) {
  const { highlighted, content, children, onClick, horizontal, before, after } =
    props;
  const theme = Theme.useTheme();

  const containerCss = css`
    background: ${highlighted
      ? theme.highlightedBackground
      : theme.panelBackground};
    color: ${highlighted ? theme.highlightedText : theme.panelText};
    border-radius: 12px;
    flex-grow: 1;
    cursor: ${onClick ? "pointer" : ""};
    display: flex;
    flex-direction: ${horizontal ? "row" : "column"};
  `;

  let contentNode: ReactNode = null;
  let contentProp = content || children;
  if (contentProp) {
    const contentCss = css`
      padding-right: ${Layout.S}px;
      padding-left: ${Layout.S}px;
      padding-top: ${Layout.S / 2}px;
      padding-bottom: ${Layout.S / 2}px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    `;
    contentNode = <div css={contentCss}>{contentProp}</div>;
  }

  return (
    <HighlightContext.Provider value={highlighted || false}>
      <div css={containerCss} onClick={onClick}>
        {before}
        {contentNode}
        {after}
      </div>
    </HighlightContext.Provider>
  );
}
