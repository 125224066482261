/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { sumBy } from "lodash";
import { Fragment, useMemo } from "react";
import { useParams } from "react-router-dom";
import Button from "../../components/Button";
import Content from "../../components/Content";
import Grid from "../../components/Grid";
import Icon from "../../components/Icon";
import Panel3 from "../../components/Panel3";
import Price from "../../components/Price";
import PriceTag from "../../components/PriceTag";
import Spacer from "../../components/Spacer";
import Text from "../../components/Text";
import Environment from "../../utilities/Environment";
import getAsset from "../../utilities/getAsset";
import Layout from "../../utilities/Layout";
import { QuotationResult } from "../../utilities/Quotations";
import Reviews from "../../utilities/Reviews";
import Theme from "../../utilities/Theme";
import useBooleanState from "../../utilities/useBooleanState";

type QuotationResultViewProps = {
  result: QuotationResult;
};

export default function QuotationResultView(props: QuotationResultViewProps) {
  const { result } = props;

  const { id } = useParams();

  const togglerCss = css`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: ${Layout.S}px;
  `;

  const [costsVisible, setDetailsVisible] = useBooleanState(false);

  const costs = useMemo(() => {
    const datas = result.calculated_datas;

    const out: Array<QuotationCost> = [];
    out.push({
      label:
        "Depuis le lieu de prise en charge jusqu'au rendu bord du navire ou au port d'arrivée",
      lines: [
        { label: "Départ", text: datas.PEC_LOCATION },
        {
          label: `Prise en charge (${datas.PEC_LOCATION})`,
          price: parseFloat(datas.PEC),
        },
        { label: "Prix du transport (port à port)", price: datas.P },
        { label: "Assurance", price: datas.A },
      ],
    });
    out.push({
      label: "Recapitulatif des taxes à titre indicatif",
      lines: [
        {
          label: "Valeur de la marchandise",
          price: datas.V,
          notInTotal: true,
        },
        {
          label: "Valeur taxable sur marchandise",
          price: datas.CAF,
          notInTotal: true,
        },
        {
          label: "Taxe sur marchandise (TSM)",
          price: datas.TSM,
        },
        {
          label: `Montant TVA (${datas.TVA} %)`,
          price: datas.TVA_VALUE,
        },
        {
          label: `Montant Octroi de mer ${
            datas.OM ? `(${datas.OM.om} %)` : ""
          }`,
          price: datas.OM_VALUES.OM,
        },
        {
          label: `Montant Octroi de mer regionnal ${
            datas.OM ? `(${datas.OM.omr} %)` : ""
          }`,
          price: datas.OM_VALUES.OMR,
        },
      ],
    });
    out.push({
      label:
        "Depuis le bord du navire / le port d'arrivée jusqu'au lieu de livraison",
      lines: [
        {
          label: "Frais de dégroupage & dédouanement",
          price: datas.FR,
        },
        {
          label: `Montant TVA sur prestation (${datas.TVA_RU || 0} %) :`,
          price: datas.TVA_RU_VALUE,
        },
        {
          label: `Livraison (${datas.PT_LOCATION}) :`,
          price: parseFloat(datas.PT),
        },
      ],
    });
    return out;
  }, [result]);

  return (
    <Fragment>
      <Spacer.Double />
      <Text typo="heading" center>
        Félicitation ! Voici votre devis gratuit
      </Text>
      <Spacer.Double />
      <Text center>
        Votre devis au format PDF vous a été envoyé par email et est également
        disponible dans votre espace Mon compte.
      </Text>
      <Spacer.Double />
      <Grid columns={2} gap={Layout.S} outerGap>
        <Panel3>
          <Fragment>
            <Spacer.Half />
            <Text typo="subheading">Votre demande</Text>
            <Spacer />
            <Text html={result.summary} typo="bigbody" color="black" />
            <Spacer.Half />
          </Fragment>
        </Panel3>
        <div css={{ minWidth: 0 }}>
          <Panel3>
            <Spacer.Half />
            <Text typo="subheading">Total de votre devis</Text>
            <Spacer />
            <PriceTag
              price={result.calculated_datas.TOTAL_A_PAYER}
              after={" TTC"}
              highlight
              typo={"subheading"}
            />
            <Spacer />
            <Text center html={result.delay.formatted} />
            <Spacer />
            <Grid columns={1} gap={Layout.S}>
              <Button
                label="Télécharger le devis"
                href={result.pdf_file}
                minor
                newTab
              />
              <Button
                label="Réserver maintenant !"
                href={result.url_reservation}
              />
              <Button
                label="Modifier ce devis"
                to={`/quotations/${id}/${result.workflow[0]}`}
                minor
              />
              <Button label="Nouveau devis" to={`/quotations`} minor />
            </Grid>
            <Spacer.Half />
          </Panel3>
        </div>
      </Grid>
      <Content>
        <div css={togglerCss} onClick={setDetailsVisible.toggle}>
          <Icon name="chevron-up" rotate={costsVisible ? 180 : 90} />
          <Spacer />
          <Text typo="bigbody">
            {`${costsVisible ? "Masquer" : "Voir"} le détail de votre devis`}{" "}
          </Text>
        </div>
        {costsVisible ? <QuotationCosts costs={costs} /> : null}
      </Content>
      <Spacer.Double />
      <Spacer.Double />
      <Reviews />
      <Spacer.Double />
      <Spacer.Double />
      <Guide />
    </Fragment>
  );
}

type QuotationCost = {
  label: string;
  lines: Array<QuotationLine>;
};

type QuotationLine = { label: string } & (
  | { price: number; notInTotal?: boolean }
  | { text: string }
);

type QuotationCostsProps = {
  costs: Array<QuotationCost>;
};

function QuotationCosts(props: QuotationCostsProps) {
  const { costs } = props;

  const lines = useMemo(() => {
    const out: Array<QuotationLineProps> = [];

    for (let cost of costs) {
      const total = sumBy(cost.lines, (l) => {
        if ("price" in l && !l.notInTotal) return l.price;
        else return 0;
      });
      out.push({ label: cost.label, price: total, total: true });

      for (let line of cost.lines) {
        if ("price" in line) {
          out.push({ label: line.label, price: line.price, total: false });
        } else {
          out.push({ label: line.label, text: line.text, total: false });
        }
      }
    }
    return out;
  }, [costs]);

  return (
    <Content>
      {lines.map((l, i) => (
        <QuotationLine {...l} key={i} />
      ))}
    </Content>
  );
}

type QuotationLineProps = {
  total: boolean;
  label: string;
} & ({ price: number } | { text: string });

function QuotationLine(props: QuotationLineProps) {
  const { total, label } = props;
  const theme = Theme.useTheme();

  const commonCss = css`
    padding-left: ${Layout.S}px;
    padding-right: ${Layout.S}px;
    padding-top: ${Layout.S}px;
    padding-bottom: ${Layout.S}px;
    display: flex;
    border-radius: 8px;
  `;

  const containerCss = total
    ? css`
        ${commonCss}
        background-color : ${theme.panelBackground};
        color: ${theme.primaryText};
      `
    : css`
        ${commonCss}
      `;

  const labelCss = css`
    flex-grow: 1;
  `;

  return (
    <div css={containerCss}>
      <div css={labelCss}>
        <Text typo={total ? "total" : "body"}>{label}</Text>
      </div>
      <Text typo={total ? "total" : "body"}>
        {"price" in props ? (
          <Price price={props.price} />
        ) : (
          <Text>{props.text}</Text>
        )}
      </Text>
    </div>
  );
}

function Guide() {
  const guideCss = css({
    minHeight: 200,
    position: "relative",
  });

  const svgContainerCss = css({
    height: 50,
    background: "red",
    position: "relative",
  });

  const svgCss = css({
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
  });

  const titleCss = css({
    position: "relative",
    zIndex: 1,
  });

  const bateauCss = css({
    height: 100,
    display: "block",
    margin: "auto",
    position: "relative",
    zIndex: 2,
  });

  const theme = Theme.useTheme();

  const contentCss = css({
    background: theme.footerGenerated,
    color: "white",
  });

  const itemCss = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBlock: Layout.S * 2,
    img: {
      width: "70%",
    },
  });

  return (
    <div css={guideCss}>
      <div css={titleCss}>
        <Text typo="heading" center>
          {Environment.findString("REACT_APP_STEPS_ORIGIN") === "expedom"
            ? "Expedom "
            : "Expesud "}
          vous guide pour un transport réussi
        </Text>
        <Spacer />
        <Text center>
          Préparez votre projet de transport en bénéficiant de conseils
          d’experts.
        </Text>
      </div>
      <Spacer.Double />
      <img
        src={getAsset("bateau.png")}
        css={bateauCss}
        alt="Bateau porte contener"
      />
      <div css={svgContainerCss}>
        <svg width={1240} height={200} css={svgCss}>
          <title>Artboard</title>
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="73.9614062%"
              id="linearGradient-1"
            >
              <stop stopColor="#FFFFFF" stopOpacity="0" offset="0%"></stop>
              <stop stopColor="#FFFFFF" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g
            id="Artboard"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <rect
              id="Rectangle"
              fill="url(#linearGradient-1)"
              x="0"
              y="0"
              width="1240"
              height="200"
            ></rect>
            <path
              d="M420,100 C420,155.228475 509.54305,200 620,200 L0,200 L0,100 L420,100 Z M1240,100 L1240,200 L620,200 C730.45695,200 820,155.228475 820,100 L1240,100 Z"
              id="Combined-Shape"
              fill={theme.footerGenerated}
            ></path>
          </g>
        </svg>
      </div>
      <div css={contentCss}>
        <Grid columns={3}>
          <div css={itemCss}>
            <Text typo="subheading">Document utiles</Text>
            <Spacer />
            <img src={getAsset("image-docs-utiles-footer.png")} />
            <Spacer />
            <Button
              label="Voir le guide"
              href={Environment.findString("REACT_APP_LINK_TO_GUIDE")}
            />
          </div>

          <div css={itemCss}>
            <Text typo="subheading">Questions fréquentes</Text>
            <Spacer />
            <img src={getAsset("image-faq-footer.png")} />
            <Spacer />
            <Button
              label="Voir la FAQ"
              href={Environment.findString("REACT_APP_LINK_TO_FAQ")}
            />
          </div>

          <div css={itemCss}>
            <Text typo="subheading">Lexique du transport</Text>
            <Spacer />
            <img src={getAsset("image-lexique-footer.png")} />
            <Spacer />
            <Button
              label="Voir le lexique"
              href={Environment.findString("REACT_APP_LINK_TO_LEXICON")}
            />
          </div>
        </Grid>
      </div>
    </div>
  );
}
