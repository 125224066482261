/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import { PropsWithChildren } from "react";
import Css from "../utilities/Css";
import Environment from "../utilities/Environment";
import Layout from "../utilities/Layout";
import Theme from "../utilities/Theme";
import getAsset from "../utilities/getAsset";
import useResponsive from "../utilities/useResponsive";
import Icon from "./Icon";
import Spacer from "./Spacer";

type ContainerProps = PropsWithChildren<{}>;

export default function Header(props: ContainerProps) {
  const HeaderComponent = useResponsive(
    {
      800: MobileHeader,
      bigger: DesktopHeader,
    },
    []
  );

  return <HeaderComponent />;
}

function MobileHeader() {
  const theme = Theme.useTheme();

  const containerCss = css`
    background-color: ${theme.mobileHeaderBackground};
    color: ${theme.mobileHeaderText};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${Layout.P}px;
    padding-bottom: ${Layout.P}px;
  `;

  const buttonsCss = css`
    ${Css.buttonReset};
    padding-left: ${Layout.P / 2}px;
    padding-right: ${Layout.P / 2}px;
  `;

  const logoCss = css`
    height: 48px;
  `;

  return (
    <header css={containerCss}>
      <button css={buttonsCss}>
        <Icon name="menu" size={32} />
      </button>
      <a href={Environment.findString("REACT_APP_LINK_TO_MAIN_SITE")}>
        <img
          css={logoCss}
          src={getAsset(theme.logo + "@1x.png")}
          srcSet={`${getAsset(theme.logo + "@1x.png")} 1x, ${getAsset(
            theme.logo + "@2x.png"
          )} 2x, ${getAsset(theme.logo + "@3x.png")} 3x`}
          alt="Logo"
        />
      </a>
      <a
        css={buttonsCss}
        href={Environment.findString("REACT_APP_ACCOUNT_URL")}
      >
        <Icon name="account" size={32} />
      </a>
    </header>
  );
}

function DesktopHeader() {
  const theme = Theme.useTheme();

  const containerStyle = css`
    height: ${Layout.desktopHeaderHeight}px;
    display: flex;
    align-items: center;
  `;

  const imageStyle = css`
    height: ${Layout.desktopHeaderHeight - Layout.S * 2}px;
  `;

  const buttonsCss = css`
    ${Css.buttonReset};
    padding-left: ${Layout.P / 2}px;
    padding-right: ${Layout.P / 2}px;
    color: ${theme.highlightedText} !important;
  `;

  return (
    <header css={containerStyle}>
      <Spacer />
      <a href={Environment.findString("REACT_APP_LINK_TO_MAIN_SITE")}>
        <img
          css={imageStyle}
          src={getAsset(theme.logo + "@1x.png")}
          srcSet={`${getAsset(theme.logo + "@1x.png")} 1x, ${getAsset(
            theme.logo + "@2x.png"
          )} 2x, ${getAsset(theme.logo + "@3x.png")} 3x`}
          alt="Logo"
        />
      </a>
      <Spacer grow />
      <a
        css={buttonsCss}
        href={Environment.findString("REACT_APP_ACCOUNT_URL")}
      >
        <Icon name="account" size={32} />
      </a>
    </header>
  );
}
