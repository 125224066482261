import Immat from "./Immat";
import Input from "./Input";
import Options from "./Options";
import Select from "./Select";

const Selector = {
  Input,
  Immat,
  Select,
  Options,
};

export default Selector;
