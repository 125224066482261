import { css } from "@emotion/react";

const heading = css`
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 32px;
  line-height: 1.4;
`;

const subheading = css`
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1.4;
`;

const body = css`
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
`;

const bigbody = css`
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.4;
`;

const bold = css`
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
`;

const total = css`
  font-family: "Lato", sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 1.4;
`;

const button = css`
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.4;
`;

const label = css`
  font-family: "Lato", sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 1.4;
`;

const step = css`
  font-family: "Lato", sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 1.4;
`;

const minor = css`
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
`;

const Typo = {
  heading,
  subheading,
  total,
  body,
  button,
  step,
  minor,
  label,
  bold,
  bigbody,
};

export type TypoName = keyof typeof Typo;

export default Typo;
