/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { without } from "lodash";
import { ReactNode, useCallback, useMemo } from "react";
import Css from "../../utilities/Css";
import useResponsive from "../../utilities/useResponsive";

type OptionsFieldProps<TOption> = {
  options: Array<TOption>;
  keyExtractor: (option: TOption) => string;
  renderOption: (option: TOption, selected: boolean) => ReactNode;
  columns?: 1 | 2 | 3;
  gap?: number;
  margin?: boolean;
} & (
  | {
      multiple: true;
      onSelect: (selection: Array<TOption>) => any;
      selection: Array<TOption>;
    }
  | {
      multiple?: false;
      onSelect: (selection: TOption | null) => any;
      selection: TOption | null;
    }
);

export default function OptionsField<TOption>(
  props: OptionsFieldProps<TOption>
) {
  const {
    selection,
    options,
    renderOption,
    keyExtractor,
    multiple,
    margin,
    onSelect,
  } = props;

  const selectedKeys = useMemo(() => {
    if (Array.isArray(selection)) return selection.map(keyExtractor);
    else {
      const out: Array<string> = [];
      if (selection) out.push(keyExtractor(selection));
      return out;
    }
  }, [selection, keyExtractor]);

  const findOption = useCallback(
    (key: string) => {
      return options.find((o) => keyExtractor(o) === key) || null;
    },
    [options]
  );

  const columns = useMemo(() => {
    let out: number = props.columns || 3;
    if (out > options.length) out = options.length;
    return out;
  }, [props.columns, options]);

  const gap = props.gap || 0;

  const onClick = useCallback(
    (key: string) => {
      if (!multiple) {
        if (selectedKeys.includes(key)) onSelect(null);
        else onSelect(findOption(key));
      } else {
        const newSelectedKeys = selectedKeys.includes(key)
          ? without(selectedKeys, key)
          : [...selectedKeys, key];
        const newSelection = newSelectedKeys
          .map(findOption)
          .filter((o) => o !== null) as Array<TOption>;
        onSelect(newSelection);
      }
    },
    [options, multiple, onSelect, selectedKeys, findOption]
  );

  const renderOptionFn = useCallback(
    (option: TOption) => {
      const optionKey = keyExtractor(option);
      const isSelected = selectedKeys.includes(optionKey);
      return (
        <div key={optionKey} onClick={() => onClick(optionKey)} css={buttonCss}>
          {renderOption(option, isSelected)}
        </div>
      );
    },
    [renderOption, onClick, selection, multiple]
  );

  const nbOfColumns = useResponsive(
    {
      600: 1,
      900: columns >= 2 ? 2 : 1,
      bigger: columns,
    },
    [columns]
  );

  const containerCss = css`
    display: grid;
    grid-template-columns: repeat(${nbOfColumns}, 1fr);
    grid-gap: ${gap}px;
    padding: ${margin ? `${gap}px` : "0px"};
    align-items: stretch;
  `;

  const buttonCss = css`
    ${Css.buttonReset}
    display : flex;
    flex-direction: column;
    align-items: stretch;
  `;

  return <div css={containerCss}>{options.map(renderOptionFn)}</div>;
}
