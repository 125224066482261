/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { flatten } from "lodash";
import { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import Panel3 from "../../../../components/Panel3";
import PriceTag from "../../../../components/PriceTag";
import Spacer from "../../../../components/Spacer";
import Text from "../../../../components/Text";
import {
  GpsField,
  GpsFieldDestination,
  GpsFieldLocation,
  GpsFieldOption,
} from "../../../../utilities/Fields";
import Layout from "../../../../utilities/Layout";
import { FieldsFormContext } from "../FieldsForm";

type DestinationSelectorProps = {
  field: GpsField;
  option: GpsFieldOption;
  location: GpsFieldLocation | null;
  value: string | null | undefined;
  onSelect: (v: string | null) => any;
};

export default function DestinationSelector(props: DestinationSelectorProps) {
  const { field, option, location, value, onSelect } = props;

  const cheapest = useMemo(() => {
    let cheapest: {
      option: GpsFieldOption;
      location: GpsFieldLocation;
      destination: GpsFieldDestination;
      price: number;
    } | null = null;

    for (let option of field.options) {
      for (let location of option.locations) {
        for (let destination of location.options) {
          if (cheapest === null || destination.total_price < cheapest.price) {
            cheapest = {
              option,
              location,
              destination,
              price: destination.total_price,
            };
          }
        }
      }
    }

    return cheapest;
  }, [field.options]);

  const locations = useMemo(() => {
    if (location) return [location];
    else return option.locations;
  }, [option, location]);

  const destinations = useMemo(() => {
    return flatten(locations.map((l) => l.options));
  }, [locations]);

  useEffect(() => {
    const isSelectable = !!destinations.find((d) => d.value === value);
    if (!isSelectable) onSelect(null);
  }, [destinations, value]);

  useEffect(() => {
    if (destinations.length === 1) {
      const dest = destinations[0];
      onSelect(dest.value);
    }
  }, [destinations]);

  const locationCss = css`
    padding: ${Layout.P}px;
  `;

  const onSelectDestination = useCallback(
    (destination: GpsFieldDestination) => {
      onSelect(destination.value);
    },
    []
  );

  return (
    <div>
      {locations.map((location) => {
        return (
          <div css={locationCss} key={location.id}>
            <Text typo="subheading">Depuis {location.label}</Text>
            <Spacer />
            <Grid columns={3} gap={Layout.S}>
              {location.options.map((destination) => {
                return (
                  <GpsFieldLocationOptionHandler
                    location={location}
                    destination={destination}
                    onSelect={() => onSelectDestination(destination)}
                    minPrice={cheapest ? cheapest.price : null}
                    value={value}
                    field={field}
                  />
                );
              })}
            </Grid>
          </div>
        );
      })}
    </div>
  );
}

type GpsFieldLocationOptionHandlerProps = {
  value: string | null | undefined;
  field: GpsField;
  location: GpsFieldLocation;
  destination: GpsFieldDestination;
  onSelect: () => any;
  minPrice: number | null;
};

function GpsFieldLocationOptionHandler(
  props: GpsFieldLocationOptionHandlerProps
) {
  const { destination, value, onSelect, location, minPrice } = props;

  const form = useContext(FieldsFormContext);
  const { isSubmitting, hasErrors } = form.useState();

  const buttonLineCss = css({
    display: "flex",
  });

  const selected = destination.value === value;

  const isMinPrice = minPrice === destination.total_price;

  return (
    <Panel3
      key={destination.id}
      highlighted={destination.value === value}
      onClick={onSelect}
      content={
        <Fragment>
          <Spacer.Half />
          <Text typo="subheading">
            {location.description} {destination.description}
          </Text>
          <Spacer />
          <PriceTag
            before="Transport : "
            price={destination.total_price}
            after=" HT"
          />
          <Spacer.Half />
          <Text>{destination.label}</Text>
          <Spacer.Half />
          <div css={css(buttonLineCss)}>
            {isMinPrice ? (
              <BestPriceTag blinking={destination.value !== value} />
            ) : null}
            <Spacer.Grow />
            {selected ? (
              <div onClick={(e) => e.stopPropagation()}>
                <Button
                  submit
                  centered
                  label="Continuer"
                  loading={isSubmitting}
                  disabled={hasErrors}
                  form={form.id}
                />
              </div>
            ) : (
              <Button.Invisible />
            )}
          </div>
          <Spacer.Half />
        </Fragment>
      }
    />
  );
}

function BestPriceTag(props: { blinking: boolean }) {
  const { blinking } = props;

  const blink = keyframes`
    0, 100% {
      opacity: 1
    }
  
    50% {
      opacity: 0.5
    }
  `;

  let containerCss = css({
    alignSelf: "center",
    display: "inline-block",
    background: "#45CC32",
    padding: Layout.S / 2,
    color: "white",
    borderRadius: 4,
  });

  if (blinking) {
    containerCss = css(containerCss, {
      animation: `${blink} 3s ease infinite`,
    });
  }

  return (
    <div css={containerCss}>
      {" "}
      <Text typo="bold">Meilleur prix !</Text>
    </div>
  );
}
