/** @jsxImportSource @emotion/react */
import { nanoid } from "nanoid";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import Api, { FnaaInfos } from "../../utilities/Api";
import { QuotationResponses } from "../../utilities/Quotations";
import useBooleanState from "../../utilities/useBooleanState";
import useFieldCss from "../../utilities/useFieldCss";
import Icon from "../Icon";
import SelectorHelp, { SelectorHelpProps } from "./Help";

export type ImmatSelectorProps = {
  placeholder?: string;
  required?: boolean;
  label?: string;
  value: FnaaInfos | null | undefined;
  onChange: (value: FnaaInfos | null) => any;
  responses: QuotationResponses;
} & SelectorHelpProps;

export default function ImmatSelector(props: ImmatSelectorProps) {
  const { value, onChange, placeholder, required, label, responses } = props;

  const fieldCss = useFieldCss();

  const id = useMemo(nanoid, []);

  const [loading, setLoading] = useBooleanState(false);
  const [invalid, setInvalid] = useBooleanState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [focused, setFocused] = useBooleanState(false);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    []
  );

  const onFocus = useCallback(() => {
    setFocused.toTrue();
    if (value) {
      setInputValue(value.__parsed.immatriculation);
      onChange(null);
    } else {
      setInvalid.toFalse();
    }
  }, [value]);

  const onBlur = useCallback(async () => {
    setFocused.toFalse();
    if (!value && inputValue) {
      try {
        setLoading.toTrue();
        setInvalid.toFalse();
        const data = await Api.getFnaaInfos(inputValue, responses);
        onChange(data);
      } catch (err) {
        setInvalid.toTrue();
      } finally {
        setLoading.toFalse();
      }
    }
  }, [value, inputValue]);

  const displayedValue = useMemo(() => {
    if (value)
      return `✅ ${value.__parsed.marque} ${value.__parsed.modele} ${value.__parsed.version} (${value.__parsed.immatriculation})`;
    else {
      if (inputValue) {
        if (loading) {
          return `⏳ Détection du véhicule en cours... (${inputValue})`;
        } else if (invalid) {
          return `⚠️ Immatriculation invalide : ${inputValue}`;
        }
      } else {
        return "";
      }
    }
    return inputValue;
  }, [inputValue, value, loading, invalid]);

  const containerCss = focused
    ? fieldCss.inputContainerFocus
    : fieldCss.inputContainer;

  const inputCss = focused ? fieldCss.inputFocus : fieldCss.input;

  return (
    <Fragment>
      <label htmlFor={id} css={fieldCss.label}>
        {label}
      </label>
      <div css={containerCss}>
        <input
          id={id}
          css={inputCss}
          type={"text"}
          required={required}
          placeholder={placeholder}
          value={displayedValue}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={loading}
        />
        <div
          css={{
            alignSelf: "center",
            display: focused ? "block" : "none",
            color: "black",
          }}
        >
          <Icon name="check" size={24} />
        </div>
      </div>
      <SelectorHelp {...props} />
    </Fragment>
  );
}
