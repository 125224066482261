/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import * as Sentry from "@sentry/react";
import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "reset-css";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import Button from "./components/Button";
import Header from "./components/Header";
import WavyContent from "./components/WavyContent";
import Widther from "./components/Widther";
import ErrorView from "./screens/ErrorView";
import QuotationContainer from "./screens/QuotationContainer";
import QuotationResumeContainer from "./screens/QuotationResult/QuotationResultContainer";
import QuotationsHome from "./screens/QuotationsHome";
import Environment from "./utilities/Environment";
import getAbsolutePath from "./utilities/getAsset";
import Theme from "./utilities/Theme";
import Typo from "./utilities/Typo";
import useResponsive from "./utilities/useResponsive";

function Root() {
  const themeName = Environment.findString("REACT_APP_THEME") as
    | "expedom"
    | "expesud";
  const theme = Theme.themes[themeName];

  const globalCss = css`
    html {
      ${Typo.body}
      color : ${theme.contentText};
      min-height: 100%;
      background-position: center;
    }
    * {
      box-sizing: border-box;
    }
    .flag {
      width: 1.5em;
      height: 1em;
      display: inline-block;
      vertical-align: middle;
      background-size: cover;
      background-position: center;
      &.flag-france {
        background-image: url("${getAbsolutePath("flags/france.png")}");
      }
      &.flag-reunion {
        background-image: url("${getAbsolutePath("flags/reunion.png")}");
      }
      &.flag-mayotte {
        background-image: url("${getAbsolutePath("flags/mayotte.png")}");
      }
      &.flag-martinique {
        background-image: url("${getAbsolutePath("flags/martinique.png")}");
      }
      &.flag-guadeloupe {
        background-image: url("${getAbsolutePath("flags/guadeloupe.png")}");
      }
    }
    .icon {
      transform: scale(1.2);
      display: inline-block;
      margin: 0px 0.2em;
    }
    a,
    a:visited {
      color: inherit;
    }
    .spinner > span {
      display: block;
    }
    .fade-enter {
      opacity: 0;
      z-index: 1;
    }
    .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 250ms ease-out;
    }
    .fade-exit {
      opacity: 1;
    }
    .fade-exit.fade-exit-active {
      opacity: 0;
      transition: opacity 250ms ease-out;
    }
  `;

  const responsiveGlobalCss = useResponsive({
    800: css`
      html {
        background-color: white;
      }
    `,
    bigger: css`
      html {
        background-image: url("${getAbsolutePath(theme.pageImage)}");
        background-repeat: no-repeat;
        background-size: cover;
      }
    `,
  });

  return (
    <Theme.Provider name={themeName}>
      <Global styles={globalCss} />
      <Global styles={responsiveGlobalCss} />
      <BrowserRouter>
        <InRouter />
      </BrowserRouter>
    </Theme.Provider>
  );
}

function InRouter() {
  return (
    <Widther>
      <Header />
      <WavyContent>
        <InContent />
      </WavyContent>
    </Widther>
  );
}

function InContent() {
  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => (
        <ErrorView>
          <Button onClick={resetError} to="/" label="Continuer" />
        </ErrorView>
      )}
    >
      <Transitionner transitionKey={useLocation().key}>
        <Routes>
          <Route
            path="/*"
            element={
              <Routes>
                <Route
                  key={"Bonjour"}
                  path="/quotations"
                  element={<QuotationsHome />}
                />
                <Route
                  path="/quotations/:id/resume"
                  element={<QuotationResumeContainer />}
                />
                <Route
                  key={"lol"}
                  path="/quotations/*"
                  element={<QuotationContainer />}
                />
                <Route path="/*" element={<Navigate to={"/quotations"} />} />
              </Routes>
            }
          />
        </Routes>
      </Transitionner>
    </Sentry.ErrorBoundary>
  );
}

type TransitionnerProps = PropsWithChildren<{ transitionKey: string }>;

function Transitionner(props: TransitionnerProps) {
  const transitionKey = props.transitionKey;
  const lastTransitionKey = useRef<ReactNode>(null);
  const ref = useRef<HTMLDivElement>(null);
  const previousDiv = useRef<HTMLDivElement>(null);

  const currentOpacity = useRef<number>(1);
  const currentOpacityAnimated = useRef<boolean>(false);
  const previousOpacity = useRef<number>(1);
  const previousOpacityAnimated = useRef<boolean>(false);

  if (
    transitionKey !== lastTransitionKey.current &&
    ref.current &&
    previousDiv.current
  ) {
    previousDiv.current.replaceChildren(ref.current.cloneNode(true));
    currentOpacity.current = 0;
    currentOpacityAnimated.current = false;
    previousOpacity.current = 1;
    previousOpacityAnimated.current = false;
  }

  const duration = 500;

  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    lastTransitionKey.current = transitionKey;
    previousOpacity.current = 0;
    previousOpacityAnimated.current = true;
    currentOpacity.current = 1;
    currentOpacityAnimated.current = true;
    setDate(new Date());
  }, [transitionKey]);

  const containerCss = css`
    position: relative;
  `;

  const currentDivCss = css`
    opacity: ${currentOpacity.current};
    transition: ${currentOpacityAnimated.current
      ? `opacity ${duration}ms`
      : "none"};
  `;

  const previousDivCss = css`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    opacity: ${previousOpacity.current};
    transition: ${previousOpacityAnimated.current
      ? `opacity ${duration}ms`
      : "none"};
    z-index: 0;
    pointer-events: none;
  `;

  return (
    <div css={containerCss}>
      <div ref={ref} css={currentDivCss}>
        {props.children}
      </div>
      <div css={previousDivCss} ref={previousDiv}></div>
    </div>
  );
}

export default Root;
