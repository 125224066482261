/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, ReactNode, useCallback, useMemo } from "react";
import "reset-css";
import Button from "../../components/Button";
import Content from "../../components/Content";
import Spacer from "../../components/Spacer";
import Text from "../../components/Text";
import WorkflowContainer from "../../components/WorkflowContainer";
import Quotations, {
  QuotationFormStepValues,
  QuotationResponses,
  QuotationStep,
} from "../../utilities/Quotations";
import StepContext from "../../utilities/StepContext";
import useFormZones from "../../utilities/useFormZones";
import FieldsForm, { FieldsFormManager } from "./FieldHandler/FieldsForm";
import FormHandler from "./FormHandler";

type StepProps = {
  step: QuotationStep;
  defaultValues: QuotationFormStepValues;
  onSubmit: (responses: QuotationResponses) => Promise<void>;
};

export default function Step(props: StepProps) {
  const { step, defaultValues = {}, onSubmit } = props;
  const quotation = Quotations.useContextQuotation();

  const zones = useFormZones(step.fields);
  const manager = useMemo(() => new FieldsFormManager({ defaultValues }), []);

  const showSubmitButton = useMemo(() => {
    const autosubmittingField = step.fields.find((f) => {
      if (
        f.type === "fnaa" ||
        f.type === "number" ||
        f.type === "text" ||
        f.type === "email" ||
        f.type === "textarea" ||
        f.type === "select" ||
        f.type === "arbo-radio"
      )
        return false;
      if (f.type === "switch_details") return true;
      if (f.type === "gps") return true;
      if (f.type === "radio") {
        const submittingOptions = f.options.filter((o) => o.redirect !== false);
        if (submittingOptions.length === f.options.length) return true;
        else return false;
      }
    });
    return !autosubmittingField;
  }, [step.fields]);

  const { isSubmitting, hasErrors } = manager.useState();

  const onSubmitForm = useCallback(async () => {
    const state = manager.getState();
    if (state.hasErrors) return;
    const values = manager.getValues();
    manager.setSubmitting(true);
    await onSubmit(values);
    manager.setSubmitting(false);
  }, []);

  const descriptionCss = css`
    width: 100%;
    max-width: 500px;
    margin: auto;
  `;

  let descriptionNode: ReactNode = null;
  if (step.description) {
    descriptionNode = (
      <Fragment>
        <div css={descriptionCss}>
          <Text center html={step.description} />
        </div>
        <Spacer />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <StepContext.Provider quotation={quotation} step={step}>
          <WorkflowContainer />
          <Spacer />
          <Content>
            <Text center typo="heading">
              {step.title}
            </Text>
            <Spacer />
            {descriptionNode}
          </Content>
          <FieldsForm onSubmit={onSubmitForm} manager={manager}>
            <FormHandler
              zones={zones}
              placeholder={step.no_field_placeholder}
            />
            {showSubmitButton ? (
              <Button
                centered
                submit
                label="Continuer"
                disabled={hasErrors}
                loading={isSubmitting}
                form={manager.id}
              />
            ) : null}
            <Spacer.Double />
          </FieldsForm>
        </StepContext.Provider>
      </div>
    </Fragment>
  );
}
