/** @jsxImportSource @emotion/react */
import { nanoid } from "nanoid";
import { ChangeEvent, Fragment, useCallback, useMemo } from "react";
import useBooleanState from "../../utilities/useBooleanState";
import useFieldCss from "../../utilities/useFieldCss";
import Text from "../Text";

export type InputSelectorProps = {
  placeholder?: string;
  required?: boolean;
  label?: string;
  onFocus?: () => any;
  help?: string;
  disabled?: boolean;
  onHelpClick?: () => any;
} & (
  | {
      type: "text" | "textarea" | "email" | "password";
      value: string | null;
      onChange: (value: string | null) => any;
    }
  | {
      type: "number" | "currency";
      value: number | null;
      onChange: (value: number | null) => any;
      min?: number;
      max?: number;
    }
);

export default function InputSelector(props: InputSelectorProps) {
  const {
    type,
    value,
    onChange,
    placeholder,
    required,
    label,
    onFocus,
    help,
    onHelpClick,
    disabled,
  } = props;

  const fieldCss = useFieldCss();

  const Component = type === "textarea" ? "textarea" : "input";

  const id = useMemo(nanoid, []);

  const inputType = useMemo(() => {
    if (type === "number") return "number";
    else if (type === "email") return "email";
    else return "text";
  }, [type]);

  const rightAppendix =
    type === "currency" ? <div css={fieldCss.rightAppendix}>€</div> : null;

  const inputValue = useMemo(() => {
    if (value === null) return "";
    else if (typeof value === "number") return value.toString();
    else return value;
  }, [value]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const str = e.target.value;
      if (type === "number" || type === "currency") {
        const nb = parseFloat(str);
        if (isNaN(nb)) onChange(null);
        else onChange(nb);
      } else if (
        type === "text" ||
        type === "textarea" ||
        type === "email" ||
        type === "password"
      ) {
        onChange(str);
      }
    },
    [onChange]
  );

  let helpNode = null;
  if (help) {
    if (onHelpClick) {
      helpNode = (
        <div css={fieldCss.help} onClick={onHelpClick}>
          <Text typo="minor" html={help} underline />
        </div>
      );
    } else {
      helpNode = (
        <div css={fieldCss.help}>
          <Text typo="minor" html={help} />
        </div>
      );
    }
  }

  const [focused, setFocused] = useBooleanState(false);

  const onInputFocus = useCallback(() => {
    setFocused.toTrue();
    if (onFocus) onFocus();
  }, []);

  const containerCss = focused
    ? fieldCss.inputContainerFocus
    : fieldCss.inputContainer;

  const inputCss = disabled
    ? fieldCss.inputDisabled
    : focused
    ? fieldCss.inputFocus
    : fieldCss.input;

  return (
    <Fragment>
      <label htmlFor={id} css={fieldCss.label}>
        {label}
      </label>
      <div css={containerCss}>
        <Component
          id={id}
          css={inputCss}
          type={type}
          min={"min" in props ? props.min : undefined}
          max={"max" in props ? props.max : undefined}
          required={required}
          placeholder={placeholder}
          value={inputValue}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={setFocused.toFalse}
          disabled={disabled}
        />
        {rightAppendix}
      </div>
      {helpNode}
    </Fragment>
  );
}
