/** @jsxImportSource @emotion/react */
import "reset-css";
import FormZone from "../../components/FormZone";
import { InputFormZone } from "../../utilities/useFormZones";
import FieldHandler from "./FieldHandler";

type InputFormZoneHandlerProps = {
  zone: InputFormZone;
};

export default function InputFormZoneHandler(props: InputFormZoneHandlerProps) {
  const { zone } = props;
  return (
    <FormZone>
      {zone.fields.map((field) => {
        return <FieldHandler field={field} key={field.id} />;
      })}
    </FormZone>
  );
}
