/** @jsxImportSource @emotion/react */
import { get } from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import FieldHandler from ".";
import Selector from "../../../components/Selector";
import { SelectorHelpProps } from "../../../components/Selector/Help";
import { FnaaSchema } from "../../../utilities/Api";
import {
  FNAAField,
  SelectField,
  SelectFieldOption,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import Quotations from "../../../utilities/Quotations";
import { FieldsFormContext } from "./FieldsForm";

export type InputFieldHandlerProps = {
  field: FNAAField;
} & SelectorHelpProps;

export default function FnaaFieldHandler(props: InputFieldHandlerProps) {
  const { field } = props;
  const form = useContext(FieldsFormContext);

  const { value, setValue } = form.useFieldManager(field.id, FnaaSchema);
  const [lockedSubfields, setLockedSubfields] = useState<Array<string>>([]);
  useFieldRequiredContraint(field);
  useMountedField(field);

  // console.log(value);

  const quotation = Quotations.useContextQuotation();
  const responses = Quotations.getFlatResponses(quotation.id, null);

  useEffect(() => {
    if (!value) {
      field.fields.forEach((subfield) => {
        form.cleanValue(subfield.id);
      });
      setLockedSubfields([]);
    } else {
      field.fields.forEach((subfield) => {
        const subfieldValue = get(value.__parsed, subfield.id);
        if (subfieldValue !== undefined) {
          setLockedSubfields((s) => [...s, subfield.id]);
          if (typeof subfieldValue === "number" && subfield.type === "select") {
            const option = searchNumberOptionInSelect(subfieldValue, subfield);
            form.setValue(subfield.id, option ? option.option.value : null);
          } else {
            form.setValue(subfield.id, subfieldValue);
          }
        }
      });
    }
  }, [value, field.fields]);

  return (
    <Fragment>
      <Selector.Immat
        value={value}
        onChange={setValue}
        label={field.title}
        responses={responses}
        {...props}
      />
      {value
        ? field.fields.map((f) => (
            <FieldHandler
              field={{ ...f, disabled: lockedSubfields.includes(f.id) }}
            />
          ))
        : null}
    </Fragment>
  );
}

function searchNumberOptionInSelect(value: number, select: SelectField) {
  const options = select.options;
  const criterias: Array<{
    from: number;
    to: number;
    option: SelectFieldOption;
  }> = [];
  options.forEach((o) => {
    if (typeof o.value === "number") {
      criterias.push({ from: o.value, to: o.value, option: o });
    } else {
      const parts = o.value.split("_");
      if (parts[0] === "less")
        criterias.push({ from: 0, to: parseInt(parts[1]) - 1, option: o });
      else if (parts[0] === "from")
        criterias.push({
          from: parseInt(parts[1]),
          to: parseInt(parts[3]) - 1,
          option: o,
        });
      else if (parts[0] === "more")
        criterias.push({ from: parseInt(parts[1]), to: Infinity, option: o });
    }
  });
  const valueOption = criterias.find((c) => value >= c.from && value <= c.to);
  return valueOption || null;
}
