/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { without } from "lodash";
import { nanoid } from "nanoid";
import { rgba } from "polished";
import {
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { any, array, Infer, record, string } from "superstruct";
import FieldHandler from ".";
import Button from "../../../components/Button";
import Grid from "../../../components/Grid";
import Icon from "../../../components/Icon";
import Panel3 from "../../../components/Panel3";
import Spacer from "../../../components/Spacer";
import Text from "../../../components/Text";
import { FnaaSchema } from "../../../utilities/Api";
import {
  FNAAField,
  FNAAGroupField,
  SelectField,
  TextField,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import Layout from "../../../utilities/Layout";
import Theme from "../../../utilities/Theme";
import useBooleanState from "../../../utilities/useBooleanState";
import FieldsForm, { FieldsFormContext, FieldsFormManager } from "./FieldsForm";

type FnaaGroupFieldProps = {
  field: FNAAGroupField;
};

export default function FnaaGroupFieldHandler(props: FnaaGroupFieldProps) {
  const { field } = props;

  const theme = Theme.useTheme();

  const containerCss = css`
    padding-top: ${Layout.S / 2}px;
    padding-bottom: ${Layout.S / 2}px;
    padding-left: ${Layout.S}px;
    padding-right: ${Layout.S}px;
  `;

  const lineCss = css({
    display: "flex",
    alignItems: "center",
  });

  const form = useContext(FieldsFormContext);
  const { value, setValue } = form.useFieldManager(
    field.id,
    array(VehiculeSchema)
  );
  useFieldRequiredContraint(field);
  useMountedField(field);

  const [newId, setNewId] = useState<string | null>(() => {
    if (value) return null;
    else return nanoid();
  });

  const toggleValue = useCallback(() => {
    if (value) setValue(null);
    else setValue([]);
  }, [value]);

  const setVehicule = useCallback(
    (id: string, vehicule: Vehicule) => {
      let out = value ? [...value] : [];
      let index: number | null = out.findIndex((v) => v.id === id);
      if (index < 0) {
        setValue([...out, { ...vehicule, id }]);
        setNewId(null);
      } else {
        out[index] = { ...vehicule, id };
        setValue(out);
      }
    },
    [value]
  );

  const removeVehicule = useCallback(
    (id: string) => {
      let out = value ? [...value] : [];
      let index: number | null = out.findIndex((v) => v.id === id);
      if (index < 0) {
        setNewId(null);
      } else {
        const item = out[index];
        out = without(out, item);
        setValue(out);
      }
    },
    [value]
  );

  let vehiculesNodes: ReactNode = null;
  if (value) {
    vehiculesNodes = value.map((v: Vehicule, index: number) => (
      <VehiculeForm
        key={v.id}
        id={v.id}
        vehicule={v}
        number={index + 1}
        onChange={(v) => setVehicule(v.id, v)}
        onDelete={() => removeVehicule(v.id)}
        field={field}
      />
    ));
    vehiculesNodes = (
      <Grid columns={2} gap={Layout.S}>
        {vehiculesNodes}
        {newId ? (
          <VehiculeForm
            key={newId}
            id={newId}
            vehicule={null}
            number={value.length + 1}
            onChange={(v) => setVehicule(newId, v)}
            onDelete={() => removeVehicule(newId)}
            field={field}
          />
        ) : (
          <div>
            <Button
              onClick={() => setNewId(nanoid())}
              label={
                value && value.length
                  ? "Ajouter un véhicule supplémentaire"
                  : "Ajouter un véhicule"
              }
            />
          </div>
        )}
      </Grid>
    );
  }

  return (
    <div css={containerCss}>
      <Panel3
        content={
          <Fragment>
            <Spacer.Half />
            <Text typo="subheading">{field.title}</Text>
            <Spacer />
            <div css={lineCss} onClick={toggleValue}>
              <Icon
                name={value ? "checkbox-marked" : "checkbox-blank-outline"}
                size={24}
                color={value ? theme.validationBackground : "black"}
              />
              <Text html={field.description}></Text>
            </div>
            <Spacer />
            {vehiculesNodes}
            <Spacer />
          </Fragment>
        }
      />
    </div>
  );
}

type VehiculeFormProps = {
  field: FNAAGroupField;
  id: string;
  vehicule: Vehicule | null;
  number: number;
  onChange: (v: Vehicule) => any;
  onDelete: () => any;
};

function VehiculeForm(props: VehiculeFormProps) {
  const { id, field, vehicule, number, onChange, onDelete } = props;
  const theme = Theme.useTheme();
  const form = useContext(FieldsFormContext);

  const containerCss = css({
    borderRadius: 10,
    padding: Layout.S,
    border: `1px solid ${rgba(theme.bordersColor, 1)}`,
  });

  const [knowsImmat, setKnowsImmat] = useBooleanState(
    vehicule ? "fnaa" in vehicule : true
  );

  const manager = useMemo(() => {
    return new FieldsFormManager({ defaultValues: vehicule || {} });
  }, [vehicule]);

  const fnaaField = manager.useFieldManager("fnaa", FnaaSchema);
  const fnaa = fnaaField.value;

  const immatField = useMemo(() => {
    let fields: Array<TextField | SelectField> = [];
    if (fnaa) {
      const option = field.options.find(
        (o) => o.id === fnaa.__parsed.type_vehicule
      );
      if (option) {
        fields = option.fields;
      }
    }

    const typeField: FNAAField = {
      type: "fnaa",
      id: "fnaa",
      title: "Immatriculation",
      description: "",
      fields,
    };
    return typeField;
  }, [fnaa]);

  const typeField = useMemo(() => {
    const typeField: SelectField = {
      type: "select",
      id: "type_vehicule",
      title: "Type de véhicule",
      description: "",
      options: field.options.map((o) => ({ label: o.label, value: o.id })),
    };
    return typeField;
  }, []);

  const { value: type } = manager.useFieldManager("type_vehicule", string());

  const otherFields = useMemo(() => {
    if (!type) return [];
    const option = field.options.find((o) => o.id === type);
    if (!option) return [];
    return option.fields;
  }, [type, field]);

  let immatNode = (
    <FieldHandler
      field={immatField}
      help="Je ne connais pas l'immatriculation"
      onClickOnHelp={setKnowsImmat.toFalse}
    />
  );

  let nonImmatNode = (
    <Fragment>
      <FieldHandler
        field={typeField}
        help="Je connais l'immatriculation"
        onClickOnHelp={setKnowsImmat.toTrue}
      />
      {otherFields.map((f) => (
        <FieldHandler field={f} key={f.id} />
      ))}
    </Fragment>
  );

  const { hasErrors } = manager.useState();

  const onSubmit = useCallback(() => {
    const values = manager.getValues();
    if ("fnaa" in values) {
      onChange({ id, ...values, ...values.fnaa });
    } else {
      onChange({ id, ...values });
    }
  }, [id]);

  const { setError } = form.useFieldManager(field.id, any());

  useEffect(() => {
    setError(`vehicule${id}`, hasErrors);
    return () => {
      setError(`vehicule${id}`, false);
    };
  }, [hasErrors, id]);

  return (
    <div css={containerCss}>
      <Text typo="subheading">
        {vehicule ? `Véhicule n°${number}` : "Nouveau véhicule"}
      </Text>
      <Spacer />
      <FieldsForm manager={manager} onSubmit={onSubmit}>
        {knowsImmat ? immatNode : nonImmatNode}
      </FieldsForm>
      <Spacer />
      <div css={css({ display: "flex" })}>
        <Button
          centered
          form={manager.id}
          label="Retirer"
          minor
          onClick={onDelete}
        />
        <Spacer.Grow />
        <Button
          centered
          submit
          form={manager.id}
          label={vehicule ? "Enregistrer" : "Ajouter"}
          disabled={hasErrors}
        />
      </div>
    </div>
  );
}

const VehiculeSchema = record(string(), any());
type Vehicule = Infer<typeof VehiculeSchema>;
