/** @jsxImportSource @emotion/react */
import "reset-css";
import { SelectorHelpProps } from "../../../components/Selector/Help";
import { AnyField } from "../../../utilities/Fields";
import ArboRadioFieldHandler from "./ArboRadioFieldHandler";
import CheckboxDefaultFieldHandler from "./CheckboxDefaultFieldHandler";
import CheckboxFieldHandler from "./CheckboxFieldHandler";
import FnaaFieldHandler from "./FnaaFieldHandler";
import FnaaGroupFieldHandler from "./FnaaGroupFieldHandler";
import GpsFieldHandler from "./GpsFieldHandler";
import NumberFieldHandler from "./NumberFieldHandler";
import RadioFieldHandler from "./RadioFieldHandler";
import SelectFieldHandler from "./SelectFieldHandler";
import SwitchDetailsFieldHandler from "./SwitchDetailsFieldHandler";
import TextFieldHandler from "./TextFieldHandler";

type FieldHandlerProps = {
  field: AnyField;
} & SelectorHelpProps;

export default function FieldHandler(props: FieldHandlerProps) {
  const { field } = props;

  if (field.type === "radio") {
    return <RadioFieldHandler field={field} />;
  } else if (field.type === "arbo-radio") {
    return <ArboRadioFieldHandler field={field} />;
  } else if (field.type === "number") {
    return <NumberFieldHandler field={field} />;
  } else if (
    field.type === "text" ||
    field.type === "email" ||
    field.type === "textarea"
  ) {
    return <TextFieldHandler field={field} />;
  } else if (field.type === "checkbox") {
    return <CheckboxFieldHandler field={field} />;
  } else if (field.type === "checkbox_default") {
    return <CheckboxDefaultFieldHandler field={field} />;
  } else if (field.type === "fnaa") {
    return <FnaaFieldHandler {...props} field={field} />;
  } else if (field.type === "select") {
    return <SelectFieldHandler {...props} field={field} />;
  } else if (field.type === "switch_details") {
    return <SwitchDetailsFieldHandler field={field} />;
  } else if (field.type === "gps") {
    return <GpsFieldHandler field={field} />;
  } else if (field.type === "fnaa-group") {
    return <FnaaGroupFieldHandler field={field} />;
  } else {
    return null;
  }
}
