/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useCallback } from "react";
import Layout from "../utilities/Layout";
import { QuotationResume } from "../utilities/Quotations";
import Theme from "../utilities/Theme";
import Text from "./Text";

type QuotationResumeTableProps = {
  resume: QuotationResume;
};

export default function QuotationResumeTable(props: QuotationResumeTableProps) {
  const { resume } = props;
  const theme = Theme.useTheme();

  const containerCss = css`
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
  `;

  const categoryCss = css`
    margin-top: ${Layout.S}px;
  `;

  const pointLineCss = css`
    position: relative;
    padding: 4px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2px;
    border: 1px solid ${rgba(theme.bordersColor, 0.2)};
    border-radius: 4px;
    overflow: hidden;
  `;

  const infoCss = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 4px;
    background: ${theme.contentBackground};
  `;

  const valueCss = css`
    flex-shrink: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 3px;
    padding-left: 12px;
    background-color: white;
  `;

  const renderLine = useCallback(
    (key: string, label: string, value: string) => {
      return (
        <div css={pointLineCss} key={key}>
          <div css={infoCss}>
            <Text html={label} typo="minor" />
          </div>
          <Text typo="body">{`\u00A0\u00A0\u00A0`}</Text>
          <div css={valueCss}>
            <Text html={value} />
          </div>
        </div>
      );
    },
    []
  );

  return (
    <div css={containerCss}>
      {resume.map((r, i) => (
        <Fragment key={r.stepKey}>
          <div css={categoryCss}>
            <Text typo="label" html={r.stepLabel} />
          </div>
          {r.points.map((p) =>
            typeof p.value === "string"
              ? renderLine(p.info, p.info, p.value)
              : p.value.map((v, i) =>
                  renderLine(i.toString(), i === 0 ? p.info : "", v)
                )
          )}
        </Fragment>
      ))}
    </div>
  );
}
