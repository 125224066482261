/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { get } from "lodash";
import {
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import Panel3 from "../../../../components/Panel3";
import PriceTag from "../../../../components/PriceTag";
import Spacer from "../../../../components/Spacer";
import Text from "../../../../components/Text";
import Api, { GeolocDestination } from "../../../../utilities/Api";
import { GpsField } from "../../../../utilities/Fields";
import geolocalize from "../../../../utilities/geolocalize";
import getDepartementNumber from "../../../../utilities/getDepartementNumber";
import Layout from "../../../../utilities/Layout";
import Quotations from "../../../../utilities/Quotations";
import StepContext from "../../../../utilities/StepContext";
import { FieldsFormContext } from "../FieldsForm";
import { Place } from "./CoordinatesSelector";

type MapSelectorProps = {
  field: GpsField;
  value: string | null | undefined;
  onChange: (value: string | null) => any;
  pec_mode: string;
  place: Place | null;
  onPlaceChange: (place: Place) => any;
};

export default function MapSelector(props: MapSelectorProps) {
  const { field, place, value, pec_mode, onChange, onPlaceChange } = props;

  const form = useContext(FieldsFormContext);
  const { isSubmitting, hasErrors } = form.useState();

  const quotation = Quotations.useContextQuotation();
  const step = StepContext.useStep();

  const id = useMemo(() => `map${Date.now()}`, []);
  const ref = useRef<HTMLDivElement>(null);
  const mapCss = css({
    height: 300,
    width: "100%",
    borderRadius: Layout.R,
  });
  const [map, setMap] = useState<any>(null);

  useEffect(() => {
    if (!ref.current) return;
    const google = get(window, "google");
    const zoom = place ? 17 : field.default_location.zoom;
    const center = place
      ? place
      : { lat: field.default_location.lat, lng: field.default_location.lng };
    const map = new google.maps.Map(ref.current, {
      zoom,
      center,
    });
    map.addListener("click", (e: MapClickedEvent) => {
      handleCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    });
    setMap(map);
  }, []);

  useEffect(() => {
    if (!map) return;
    const center = place ? place : { lat: 0, lng: 0 };
    map.setCenter(center);
  }, [place]);

  useEffect(() => {
    let marker: any = null;
    const google = get(window, "google");
    if (place && map) {
      marker = new google.maps.Marker({
        position: place,
        map: map,
      });
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [place]);

  const [destinations, setDestinations] =
    useState<Array<GeolocDestination> | null>(null);

  useEffect(() => {
    if (!place) setDestinations(null);
    else fetchDesinations(place);
  }, [place]);

  const fetchDesinations = useCallback(
    async (place: Place) => {
      setDestinations(null);
      const responses = Quotations.getFlatResponses(quotation.id, step.key);
      const destinations = await Api.getGeolocDestinations(
        responses,
        place.departement,
        place.city,
        place.lat,
        place.lng,
        step.key,
        pec_mode
      );
      setDestinations(destinations);
    },
    [pec_mode]
  );

  const handleCoordinates = useCallback(
    (coords: { lat: number; lng: number }) => {
      const google = get(window, "google");
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: coords }, (results: any) => {
        let label: string | null = null;
        let city: string | null = null;
        let departementCode: string | null = null;
        for (let result of results) {
          const country = result.address_components.find((c: any) =>
            c.types.includes("country")
          );
          if (!country) continue;
          if (!["FR", "RE", "GP", "MQ", "YT"].includes(country.short_name))
            continue;
          const region = result.address_components.find((c: any) =>
            c.types.includes("administrative_area_level_1")
          );
          if (region && region.long_name === "Corse") {
            continue;
          }
          const dep =
            getAddressComponent(result, "administrative_area_level_2") ||
            getAddressComponent(result, "political");

          const cp = result.address_components.find((c: any) =>
            c.types.includes("postal_code")
          );
          const town =
            getAddressComponent(result, "locality") ||
            getAddressComponent(result, "administrative_area_level_3") ||
            getAddressComponent(result, "administrative_area_level_2") ||
            getAddressComponent(result, "administrative_area_level_1");
          if (dep && cp && town) {
            departementCode = getDepartementNumber(cp.long_name);
            city = town;
            label =
              city + ", " + dep + " (département " + departementCode + ")";
          }
        }
        if (label && departementCode && city) {
          onPlaceChange({
            lat: coords.lat,
            lng: coords.lng,
            departement: departementCode,
            city,
            description: label,
          });
        } else {
          alert("Impossible de poursuire à cet endroit");
        }
      });
    },
    []
  );

  const geoloc = useCallback(() => {
    geolocalize(handleCoordinates);
  }, []);

  let leftNode: ReactNode = null;
  if (destinations) {
    leftNode = destinations.map((d) => (
      <Fragment key={d.value}>
        <Panel3
          highlighted={value === d.value}
          onClick={() => {
            value === d.value ? onChange(null) : onChange(d.value);
          }}
        >
          <Spacer />
          <Text typo="subheading">{d.transit + " - " + d.title}</Text>
          <Spacer />
          <PriceTag price={d.total_price} before="Transport : " />
          <Spacer />
          <Text>{d.description}</Text>
          <Spacer />
          {value === d.value ? (
            <div onClick={(e) => e.stopPropagation()}>
              <Button
                centered
                label="Sélectionner"
                submit
                form={form.id}
                loading={isSubmitting}
                disabled={hasErrors}
              />
            </div>
          ) : (
            <Button.Invisible />
          )}
          <Spacer />
        </Panel3>
        <Spacer />
      </Fragment>
    ));
  } else if (place) {
    leftNode = <Text center>Chargement en cours...</Text>;
  } else {
    leftNode = (
      <Fragment>
        <Text center typo="subheading">
          ↑ Saisissez une adresse ↑
        </Text>
        <Spacer />
        <Text center>ou</Text>
        <Spacer />
        <Text center typo="subheading">
          Sélectionnez un point sur le carte
        </Text>
        <Spacer />
        <Text center>ou</Text>
        <Spacer />
        <Text center typo="subheading">
          Recherchez au plus proche de vous
        </Text>
        <Spacer />
        <Button centered label="Me géolocaliser" onClick={geoloc} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Grid
        columns={2}
        gap={Layout.S}
        outerGap
        css={css({ alignItems: "start" })}
      >
        <div>{leftNode}</div>
        <div id={id} css={mapCss} ref={ref}></div>
      </Grid>
    </Fragment>
  );
}

type MapClickedEvent = {
  latLng: {
    lat: () => number;
    lng: () => number;
  };
};

export function getAddressComponent(result: any, type: string): string | null {
  const component = result.address_components.find((c: any) =>
    c.types.includes(type)
  );
  if (!component) return null;
  return component.long_name || null;
}
