import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo
} from "react";
import Fields, { AnyField } from "./Fields";
import Quotations, { QuotationModel, QuotationStep } from "./Quotations";

const context = React.createContext<{
  quotation: QuotationModel;
  step: QuotationStep;
} | null>(null);

const useQuotation = () => {
  const value = useContext(context);
  if (!value) throw new Error("No context");
  return value.quotation;
};

const useStep = () => {
  const value = useContext(context);
  if (!value) throw new Error("No context");
  return value.step;
};

const getFieldId = (field: AnyField) => {
  return field.id;
};

const getStepKey = (step: QuotationStep) => {
  return step.key;
};

const getStepName = (step: QuotationStep) => {
  return getStepKey(step).split("/")[1];
};

const useResumeUpdater = (
  field: AnyField,
  value: string | Array<string> | null | (() => string | Array<string> | null)
) => {
  const quotation = useQuotation();
  const step = useStep();
  const output = typeof value === "function" ? value() : value;
  const textValue = Array.isArray(output) ? output.join() : output;
  useEffect(() => {
    Quotations.addResume(quotation.id, step, field, output);
  }, [field, textValue]);
};

type ProviderProps = PropsWithChildren<{
  quotation: QuotationModel;
  step: QuotationStep;
}>;

const getFields = (step: QuotationStep) => {
  let base: Array<AnyField> = [];
  step.fields.forEach((field) => {
    const subfields = Fields.getSubFields(field);
    base = [...base, ...subfields];
  });
  return base;
};

const Provider = (props: ProviderProps) => {
  const value = useMemo(() => props, [props.quotation, props.step]);
  return <context.Provider value={value}>{props.children}</context.Provider>;
};

const StepContext = {
  Provider,
  useStep,
  useQuotation,
  getStepKey,
  getStepName,
  useResumeUpdater,
  getFieldId,
  getFields,
};

export default StepContext;
