function findString(key: string) {
  const inLs = localStorage.getItem(key);
  if (inLs !== null) return inLs;
  const value = process.env[key];
  if (!value) throw new Error("Missing env. variable");
  return value.toString();
}

const Environment = {
  findString,
};

export default Environment;
