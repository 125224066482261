/** @jsxImportSource @emotion/react */

import useFieldCss from "../../utilities/useFieldCss";
import Text from "../Text";
export type SelectorHelpProps = {
  help?: string;
  onClickOnHelp?: () => any;
};

export default function SelectorHelp(props: SelectorHelpProps) {
  const { help, onClickOnHelp } = props;
  const fieldCss = useFieldCss();

  let helpNode = null;
  if (help) {
    if (onClickOnHelp) {
      helpNode = (
        <div css={fieldCss.help} onClick={onClickOnHelp}>
          <Text typo="minor" html={help} underline />
        </div>
      );
    } else {
      helpNode = (
        <div css={fieldCss.help}>
          <Text typo="minor" html={help} />
        </div>
      );
    }
  }

  return helpNode;
}
