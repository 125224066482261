/** @jsxImportSource @emotion/react */
import { isNumber } from "lodash";
import { useContext, useEffect } from "react";
import "reset-css";
import { number } from "superstruct";
import Selector from "../../../components/Selector";
import {
  NumberField,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import StepContext from "../../../utilities/StepContext";
import { FieldsFormContext } from "./FieldsForm";

export type NumberFieldHandlerProps = {
  field: NumberField;
};

export default function NumberFieldHandler(props: NumberFieldHandlerProps) {
  const { field } = props;

  const form = useContext(FieldsFormContext);
  const { value, setValue, setError } = form.useFieldManager(
    field.id,
    number()
  );

  const min = (field.constraints && field.constraints.min) || undefined;
  const max = (field.constraints && field.constraints.max) || undefined;

  useFieldRequiredContraint(field);
  useMountedField(field);

  useEffect(() => {
    const isErrored = isNumber(min) && isNumber(value) ? value < min : false;
    setError("Valeur trop petite", isErrored);
  }, [value, min]);

  useEffect(() => {
    const isErrored = isNumber(max) && isNumber(value) ? value > max : false;
    setError("Valeur trop grande", isErrored);
  }, [value, max]);

  StepContext.useResumeUpdater(
    field,
    typeof value === "number" ? value.toString() : null
  );

  return (
    <Selector.Input
      type={field["data-type"] === "currency" ? "currency" : "number"}
      value={value === undefined ? null : value}
      onChange={setValue}
      placeholder={field.description}
      label={field.title}
      min={min}
      max={max}
    />
  );
}
