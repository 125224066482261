import * as Sentry from "@sentry/react";

export default function geolocalize(
  cb: (coord: { lat: number; lng: number }) => any
) {
  navigator.geolocation.getCurrentPosition(
    (p) => {
      cb({ lat: p.coords.latitude, lng: p.coords.longitude });
    },
    (e) => {
      Sentry.captureEvent(e);
      alert(
        "Impossible de déterminer votre position géographique pour le moment. Veuillez vérifier que la géolocalisation est activée dans les préférences de votre ordinateur et votre navigateur."
      );
    }
  );
}
