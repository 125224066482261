/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect, useMemo } from "react";
import Select, { SingleValue } from "react-select";
import useFieldCss from "../../utilities/useFieldCss";
import Text from "../Text";
import SelectorHelp, { SelectorHelpProps } from "./Help";

type SelectFieldProps<TOption> = {
  id?: string;
  label?: string;
  leadingIcon?: string;
  options: Array<TOption>;
  keyExtractor: (option: TOption) => string;
  renderOption: (option: TOption) => string;
  onSelect: (selection: TOption | null) => any;
  selection: TOption | null;
  placeholder?: string;
  disabled?: boolean;
} & SelectorHelpProps;

export default function SelectField<TOption>(props: SelectFieldProps<TOption>) {
  const {
    id,
    label,
    options,
    keyExtractor,
    renderOption,
    placeholder,
    onSelect,
    selection,
    disabled,
  } = props;

  const selectOptions = useMemo(() => {
    return options.map((o) => ({
      value: o,
      label: renderOption(o),
    }));
  }, [options, keyExtractor, renderOption]);

  const value = useMemo(() => {
    const selectedOptionValue = selection ? keyExtractor(selection) : null;
    return (
      selectOptions.find(
        (o) => keyExtractor(o.value) === selectedOptionValue
      ) || null
    );
  }, [selectOptions, selection, keyExtractor, renderOption]);

  const onChange = useCallback(
    (d: SingleValue<typeof selectOptions[number]>) => {
      onSelect(d ? d.value : null);
    },
    [options, onSelect]
  );

  useEffect(() => {
    if (selectOptions.length !== 1) return;
    if (selection) return;
    const singleOption = selectOptions[0];
    onChange(singleOption);
  }, [selectOptions, onChange, selection]);

  const fieldCss = useFieldCss();

  const allCss = css`
    .react-select__container {
      ${fieldCss.inputContainer}
      .react-select__control {
        border: none;
        flex-grow: 1;
        border-radius: 8px;
        .react-select__value-container {
          padding: 0px;
          .react-select__placeholder {
            ${fieldCss.input}
          }
          .react-select__input-container {
            ${fieldCss.input}
          }
          .react-select__single-value {
            ${fieldCss.input}
          }
        }
        &.react-select__control--is-disabled {
          background-color: lightgrey;
          color: grey;
          .react-select__placeholder {
            color: grey;
          }
          .react-select__input-container {
            color: grey;
          }
          .react-select__single-value {
            color: grey;
          }
          .react-select__indicators {
            display: none;
          }
        }

        &.react-select__control--is-focused {
          .react-select__value-container {
            .react-select__placeholder {
              ${fieldCss.inputFocus}
            }
            .react-select__input-container {
              ${fieldCss.inputFocus}
            }
            .react-select__single-value {
              ${fieldCss.inputFocus}
            }
          }
        }
      }
      .react-select__menu {
        color: black;
      }
    }
  `;

  return (
    <div css={allCss}>
      {label ? (
        <Text component="label" typo="label" css={fieldCss.label}>
          {label}
        </Text>
      ) : null}
      <Select
        id={props.id}
        className="react-select__container"
        classNamePrefix="react-select"
        options={selectOptions}
        placeholder={placeholder || "Choisissez..."}
        onChange={onChange}
        value={value}
        isDisabled={disabled}
      />
      <SelectorHelp {...props} />
    </div>
  );
}
