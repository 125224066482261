import { useEffect, useMemo, useState } from "react";
import Selector from "../../../../components/Selector";
import { GpsField } from "../../../../utilities/Fields";

type OptionSelectorProps = {
  field: GpsField;
  selection: string | null;
  onSelect: (value: string | null) => any;
};

type Option = { value: string; label: string };

export default function OptionSelector(props: OptionSelectorProps) {
  const { field, onSelect } = props;

  // Option

  const options = useMemo<Array<Option>>(() => {
    const options = field.options.map((o) => ({
      value: o.label,
      label: o.description,
    }));
    if (field["use-domicile"] === true)
      options.push({ value: "domicile", label: "Retrait à domicile" });
    if (field["use-convoyage"] === true)
      options.push({ value: "convoyage", label: "Convoyage" });
    return options;
  }, [field]);

  const [selection, setSelection] = useState<Option | null>(() => {
    const o = options.find((o) => o.value === props.selection);
    return o || null;
  });

  useEffect(() => {
    onSelect(selection ? selection.value : null);
  }, [selection]);

  return (
    <Selector.Select
      options={options}
      keyExtractor={(o) => o.value}
      renderOption={(o) => o.label}
      selection={selection}
      onSelect={setSelection}
      placeholder={field.description}
    />
  );
}
