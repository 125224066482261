import { DependencyList, useCallback } from "react";
import useBooleanState from "./useBooleanState";

/**
 * Equivalent de useState limité aux valeurs binaires (gain de perf grâce à memoïsation)
 *
 * @returns state : Etat
 * @returns setState.toFalse()
 * @returns setState.toTrue()
 * @returns setState.to(state)
 * @returns setState.toggle()
 */

export default function useAsyncCallback<TReturn>(
  cb: () => Promise<TReturn>,
  deps: DependencyList
) {
  const [loading, setLoading] = useBooleanState(false);

  const callback = useCallback(async () => {
    setLoading.toTrue();
    try {
      const result = await cb();
      setLoading.toFalse();
      return result;
    } catch (err) {
      setLoading.toFalse();
      throw err;
    }
  }, deps);

  return [callback, loading] as const;
}
