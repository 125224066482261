import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Root from "./Root";
import Environment from "./utilities/Environment";

Sentry.init({
  dsn: Environment.findString("REACT_APP_SENTRY_DSN"),
  environment: Environment.findString("REACT_APP_SENTRY_ENVIRONMENT"),
});

// Types overload
declare module "@sentry/react" {
  class ErrorBoundary {
    render(): any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
