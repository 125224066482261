import { rgba } from "polished";
import React, { PropsWithChildren, useContext } from "react";

type ImageUrl = string;
type BackgroundGradient = string;
type ColorCode = string;

type ThemeContext = {
  pageImage: ImageUrl;
  logo: ImageUrl;
  contentBackground: ColorCode;
  contentText: ColorCode;
  primaryText: ColorCode;
  panelBackground: ColorCode;
  panelText: ColorCode;
  highlightedBackground: BackgroundGradient;
  highlightedText: ColorCode;
  validationBackground: ColorCode;
  validationText: ColorCode;
  mobileHeaderBackground: ColorCode;
  mobileHeaderText: ColorCode;
  bordersColor: ColorCode;
  wavesBackground: BackgroundGradient;
  inputBackground: ColorCode;
  priceTag: ColorCode;
  footerGenerated: ColorCode;
};

const expedom: ThemeContext = {
  pageImage: "fond-expedom.jpg",
  logo: "logo-expedom",
  contentBackground: "#FFFFFF",
  contentText: "#344A53",
  primaryText: "#005276",
  panelBackground: rgba("#F8F8F8", 0.9),
  panelText: "#344A53",
  highlightedBackground:
    "linear-gradient(233.58deg, #00A8D1 -8.49%, #005276 94.36%);",
  highlightedText: "#FFFFFF",
  validationBackground: "#F49531",
  validationText: "#FFFFFF",
  mobileHeaderBackground: "#005276",
  mobileHeaderText: "#FFFFFF",
  bordersColor: "#97AFBA",
  wavesBackground:
    "linear-gradient(90deg, rgba(0,168,209,1) 0%, rgba(0,82,118,1) 100%)",
  inputBackground: "white",
  priceTag: "#00A8D1",
  footerGenerated: "#003366",
};

const expesud: ThemeContext = {
  pageImage: "fond-expedom.jpg",
  logo: "logo-expesud",
  contentBackground: "#FFFFFF",
  contentText: "#344A53",
  primaryText: "#005276",
  panelBackground: rgba("#F8F8F8", 0.9),
  panelText: "#344A53",
  highlightedBackground:
    "linear-gradient(233.58deg, #3b96a6 -8.49%, #20525b 94.36%);",
  highlightedText: "#FFFFFF",
  validationBackground: "#bf2718",
  validationText: "#FFFFFF",
  mobileHeaderBackground: "#20525b",
  mobileHeaderText: "#FFFFFF",
  bordersColor: "#97AFBA",
  wavesBackground:
    "linear-gradient(90deg, rgba(59,150,166,1) 0%, rgba(32,82,91,1) 100%)",
  inputBackground: "white",
  priceTag: "#00A8D1",
  footerGenerated: "#112d31",
};

const themes = {
  expedom,
  expesud,
};

type ThemeName = keyof typeof themes;

const Context = React.createContext<ThemeContext>(expedom);

type ProviderProps = PropsWithChildren<{
  name: ThemeName;
}>;

function useTheme() {
  return useContext(Context);
}

const Provider = (props: ProviderProps) => {
  return (
    <Context.Provider value={themes[props.name]}>
      {props.children}
    </Context.Provider>
  );
};

const Theme = {
  Provider,
  useTheme,
  themes,
};

export default Theme;
