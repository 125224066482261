/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { Fragment, ReactNode, useCallback } from "react";
import { Link } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import Css from "../utilities/Css";
import Layout from "../utilities/Layout";
import Theme from "../utilities/Theme";
import Typo from "../utilities/Typo";

type ButtonProps = {
  label: string;
  centered?: boolean;
  minor?: boolean;
  disabled?: boolean;
  loading?: boolean;
} & (
  | { submit: true; form?: string }
  | { href: string; newTab?: boolean }
  | { to: string }
  | { onClick: () => any }
  | {}
);

export default function Button(props: ButtonProps) {
  const { minor, disabled = false, loading = false } = props;
  const theme = Theme.useTheme();
  const major = !minor;

  const containerStyle = css`
    ${Css.buttonReset}
    ${Typo.button}
      background-color : ${major ? theme.validationBackground : "transparent"};
    color: ${major ? theme.validationText : "currentColor"} !important;
    border: 1px solid ${major ? theme.validationBackground : "currentColor"};
    border-radius: 4px;
    padding-left: ${Layout.P}px;
    padding-right: ${Layout.P}px;
    padding-top: ${Layout.P / 2}px;
    padding-bottom: ${Layout.P / 2}px;
    text-align: center;
    min-width: 0px;
    filter: ${disabled ? "grayscale(1)" : "none"};
    position: relative;
  `;

  const textCss = css`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: ${loading ? 0 : 1};
    display: inline-block;
    transition: opacity 200ms;
  `;

  const spinnerCss = css`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: ${loading ? 1 : 0};
    transition: opacity 200ms;
    .spinner {
      width: 70%;
    }
  `;

  const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  const onClick = useCallback((e: React.MouseEvent) => {
    if (!("onClick" in props)) return;
    props.onClick();
  }, []);

  const onClickSubmit = useCallback(
    (e: React.MouseEvent) => {
      if (disabled) {
        e.preventDefault();
        alert(
          "Vérifiez que tous les champs sont correctement remplis pour continuer."
        );
      }
    },
    [disabled]
  );

  let contentNode = (
    <Fragment>
      <div css={textCss}>{props.label}</div>
      <div css={spinnerCss}>
        <div className="spinner">
          <BarLoader color="white" width={"100%"} loading={true} />
        </div>
      </div>
    </Fragment>
  );

  let buttonNode: ReactNode = null;
  if ("to" in props) {
    buttonNode = (
      <Link to={props.to} css={containerStyle}>
        {contentNode}
      </Link>
    );
  } else if ("href" in props) {
    buttonNode = (
      <a
        href={props.href}
        target={props.newTab ? "_blank" : undefined}
        rel="noreferrer"
        css={containerStyle}
      >
        {contentNode}
      </a>
    );
  } else if ("submit" in props) {
    buttonNode = (
      <button
        type="submit"
        css={containerStyle}
        form={props.form}
        value={"TCE"}
        onClick={onClickSubmit}
      >
        {contentNode}
      </button>
    );
  } else {
    buttonNode = (
      <button type="button" css={containerStyle} onClick={onClick}>
        {contentNode}
      </button>
    );
  }

  if (props.centered) {
    buttonNode = <div css={wrapperStyle}>{buttonNode}</div>;
  }

  return buttonNode;
}

Button.Invisible = () => {
  const containerCss = css`
    ${Typo.button};
    padding-left: ${Layout.P}px;
    padding-right: ${Layout.P}px;
    padding-top: ${Layout.P / 2}px;
    padding-bottom: ${Layout.P / 2}px;
    color: transparent;
    border: 1px solid transparent;
  `;
  return <div css={containerCss}>-</div>;
};
