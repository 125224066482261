/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, PropsWithChildren } from "react";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import Css from "../utilities/Css";

type ErrorViewProps = PropsWithChildren<{
  message?: string;
}>;

export default function ErrorView(props: ErrorViewProps) {
  const containerCss = css`
    flex-grow: 1;
    ${Css.centerContent}
    flex-direction : column;
  `;

  return (
    <div css={containerCss}>
      <Spacer.Double />
      <Text typo="heading">{props.message || "Un problème est survenu"}</Text>
      <Spacer.Double />
      {props.children ? (
        <Fragment>
          {props.children}
          <Spacer.Double />
        </Fragment>
      ) : null}
    </div>
  );
}
