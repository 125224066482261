/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useMemo } from "react";
import HighlightContext from "../utilities/HighlightContext";
import Layout from "../utilities/Layout";
import Theme from "../utilities/Theme";
import { TypoName } from "../utilities/Typo";
import Text from "./Text";
type PriceTagProps = {
  price: number;
  before?: string;
  after?: string;
  highlight?: boolean;
  typo?: TypoName;
};

export default function PriceTag(props: PriceTagProps) {
  const { price, before, after, highlight, typo } = props;
  const theme = Theme.useTheme();

  const onHighlight = useContext(HighlightContext);
  const highlighted = highlight !== undefined ? highlight : onHighlight;

  const containerCss = css`
    background-color: ${highlighted ? theme.priceTag : "white"};
    border-radius: 22px;
    border: 1px solid ${theme.priceTag};
    padding: ${Layout.P / 2}px;
    color: ${highlighted ? "white" : theme.priceTag};
  `;

  const formattedPrice = useMemo(() => {
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(price);
  }, [price]);

  return (
    <div css={containerCss}>
      <Text typo={typo} center>{`${before || ""}${formattedPrice}${
        after || ""
      }`}</Text>
    </div>
  );
}
