/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PropsWithChildren } from "react";
import PropsWithCss from "../utilities/PropsWithCss";
import useResponsive from "../utilities/useResponsive";

type GridProps = PropsWithCss<
  PropsWithChildren<{
    columns?: 1 | 2 | 3;
    gap?: number;
    outerGap?: boolean;
  }>
>;

export default function Grid(props: GridProps) {
  const columns = useResponsive({
    600: 1,
    bigger: props.columns || 1,
  });
  const gap = props.gap || 0;
  const outerGap = props.outerGap ? gap : 0;

  const containerCss = css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    grid-gap: ${gap}px;
    padding: ${outerGap}px;
  `;

  return (
    <div css={containerCss} className={props.className}>
      {props.children}
    </div>
  );
}
