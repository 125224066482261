import { css } from "@emotion/react";

const buttonReset = css`
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  font-family: inherit;
  font-size: inherit;
  display: flex;
  text-align: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
`;

const centerContent = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const absoluteFill = css`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

const Css = {
  buttonReset,
  centerContent,
  absoluteFill,
};

export default Css;
