import { css } from "@emotion/react";
import { rgba } from "polished";
import Layout from "./Layout";
import Theme from "./Theme";
import Typo from "./Typo";

export default function useFieldCss() {
  const theme = Theme.useTheme();

  const label = css`
    ${Typo.label}
    padding : ${Layout.P / 2}px;
    display: block;
    width: 100%;
  `;

  const inputContainer = css`
    border: 1px solid ${theme.bordersColor};
    border-radius: 8px;
    background-color: ${theme.inputBackground};
    display: flex;
    flex-direction: row;
    width: 100%;
    transition: all 200ms;
  `;

  const inputContainerFocus = css(
    inputContainer,
    css`
      border: 1px solid ${theme.primaryText};
    `
  );

  const input = css`
    ${Typo.body}
    border : none;
    padding: ${Layout.P / 2}px;
    margin: 0px;
    flex-grow: 1;
    color: black;
    border-radius: 8px;
    -moz-appearance: textfield;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    :focus {
      outline: none;
      border-radius: 8px;
    }
    transition: all 200ms;
  `;

  const inputDisabled = css(input, {
    backgroundColor: "lightgrey",
    color: "grey",
  });

  const inputFocus = css(input, {
    backgroundColor: `${rgba(theme.primaryText, 0.05)}`,
    color: theme.primaryText,
  });

  const rightAppendix = css`
    ${Typo.label}
    border : none;
    padding: ${Layout.P / 2}px;
    padding-left: ${Layout.P}px;
    padding-right: ${Layout.P}px;
    background-color: ${theme.panelBackground};
    flex-shrink: 0;
    border-left: 1px solid ${theme.bordersColor};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  `;

  const help = css`
    ${Typo.minor}
    padding-left : ${Layout.P / 2}px;
    padding-right: ${Layout.P / 2}px;
    padding-top: 4px;
  `;

  return {
    label,
    inputContainer,
    inputContainerFocus,
    input,
    inputDisabled,
    inputFocus,
    rightAppendix,
    help,
  };
}
