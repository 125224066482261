/** @jsxImportSource @emotion/react */
import { useContext } from "react";
import "reset-css";
import { string } from "superstruct";
import Selector from "../../../components/Selector";
import {
  EmailField,
  TextareaField,
  TextField,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import StepContext from "../../../utilities/StepContext";
import { FieldsFormContext } from "./FieldsForm";

export type TextFieldHandlerProps = {
  field: TextField | EmailField | TextareaField;
};

export default function TextFieldHandler(props: TextFieldHandlerProps) {
  const { field } = props;

  const form = useContext(FieldsFormContext);

  const { value, setValue } = form.useFieldManager(field.id, string());
  useFieldRequiredContraint(field);
  useMountedField(field);

  StepContext.useResumeUpdater(field, value || null);

  return (
    <Selector.Input
      type={field.type}
      value={value || ""}
      onChange={setValue}
      placeholder={field.description}
      label={field.title}
      disabled={field.disabled}
    />
  );
}
