import * as Sentry from "@sentry/react";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuotationResult from ".";
import Api from "../../utilities/Api";
import Quotations, { QuotationModel } from "../../utilities/Quotations";
import ErrorView from "../ErrorView";
import Loading from "../Loading";

export default function QuotationResultContainer() {
  const quotationId = useParams().id || "";
  const quotation = Quotations.useQuotation(quotationId);
  const navigate = useNavigate();

  const createQuotation = useCallback(
    async (quotation: QuotationModel) => {
      if (!quotation) return;
      if (quotation.result) return;
      const responses = Quotations.getResponses(quotation.id);
      const details = await Api.finalizeQuotation(responses);
      if (details.calculated_datas === false) {
        Quotations.saveResult(quotation.id, false);
        Sentry.captureEvent({
          message: "Erreur lors de la création d'un devis",
          extra: { responses },
        });
      } else {
        Quotations.saveResult(quotation.id, details);
      }
    },
    [quotationId]
  );

  useEffect(() => {
    if (quotation) {
      createQuotation(quotation);
    } else {
      navigate("/quotations/new");
    }
  }, [quotationId]);

  if (!quotation) return null;
  if (quotation.result === null) {
    return <Loading />;
  } else if (quotation.result === false) {
    return <ErrorView />;
  } else {
    return <QuotationResult result={quotation.result} />;
  }
}
