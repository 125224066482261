/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, ReactNode, useCallback, useContext } from "react";
import "reset-css";
import { boolean } from "superstruct";
import Button from "../../../components/Button";
import Panel3 from "../../../components/Panel3";
import Spacer from "../../../components/Spacer";
import Text from "../../../components/Text";
import {
  CheckboxField,
  useFieldRequiredContraint,
  useMountedField,
} from "../../../utilities/Fields";
import Layout from "../../../utilities/Layout";
import StepContext from "../../../utilities/StepContext";
import StopClick from "../../../utilities/StopClick";
import useResponsive from "../../../utilities/useResponsive";
import { FieldsFormContext } from "./FieldsForm";

export type CheckboxFieldHandlerProps = {
  field: CheckboxField;
};

export default function CheckboxFieldHandler(props: CheckboxFieldHandlerProps) {
  const { field } = props;

  const form = useContext(FieldsFormContext);
  const { value, setValue } = form.useFieldManager(field.id, boolean());
  useFieldRequiredContraint(field);
  useMountedField(field);

  const select = useCallback(() => {
    setValue(true);
  }, []);

  const unselect = useCallback(() => {
    setValue(false);
  }, []);

  StepContext.useResumeUpdater(field, value ? "Oui" : "Non");

  const containerCss = css`
    padding-top: ${Layout.S / 2}px;
    padding-bottom: ${Layout.S / 2}px;
    padding-left: ${Layout.S}px;
    padding-right: ${Layout.S}px;
  `;

  let imageNode: ReactNode = null;

  const sizingProp = useResponsive({
    800: "height",
    bigger: "width",
  });

  if (field.lightbox) {
    const imageCss = css`
      margin: 4px;
      background-image: url(${field.lightbox.src});
      background-size: cover;
      background-position: center;
      border-radius: ${Layout.R}px;
      flex-shrink: 0;
      ${sizingProp}: 200px;
    `;
    imageNode = <div css={imageCss}></div>;
  }

  const horizontal = useResponsive({
    800: false,
    bigger: true,
  });

  return (
    <div css={containerCss}>
      <Panel3
        horizontal={horizontal}
        highlighted={value === true}
        before={imageNode}
        onClick={select}
        content={
          <Fragment>
            <Spacer.Half />
            <Text typo="subheading">{field.title}</Text>
            <Spacer />
            <Text html={field.description}></Text>
            <Spacer.Half />
            <Spacer />
            {value ? (
              <StopClick>
                <Button
                  centered
                  onClick={unselect}
                  label="Déselectionner"
                  minor
                />
              </StopClick>
            ) : (
              <Button.Invisible />
            )}
          </Fragment>
        }
      />
    </div>
  );
}
